import React from 'react';
import { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import checked from '../images/checked.svg'
import tshirt from '../images/tshirt.png'
import CustomerReview from '../Componants/CustomerReview';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { AddtoCart, GetCartCount, GetSingleProduct } from '../Redux/Features/Shopslice';
import { message } from 'antd';
import Skeleton from 'react-loading-skeleton';
import CartView from './CartView';
function ShopDetailView() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [viewCart, setviewCart] = useState(false);
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  const { state } = useLocation()
  const { SingleProduct, loading } = useSelector(state => state.Shop)
  const { id } = useParams()
  
  const navigate = useNavigate()
  const dispatch = useDispatch()
  console.log(SingleProduct, 'SingleProduct in detail page');

  // function for adding product type to the cart
  const handleAddCart = ({id,stock}) => {
    // !CartView ?

    if (viewCart) {
      navigate('/cart')
    }else{
     
      stock===0 && message.error('Product out of stock')

      dispatch(AddtoCart(id)).then((res) => {
        console.log(res, 'res after adding to cart');

        if (res.type === "product/AddtoCart/fulfilled") {
          message.success('item successfully added to cart')
        setviewCart(true)
        dispatch(GetCartCount())
        }else{
          // message.info(res.payload.message)
        }
          
      })
    }
      
      //  :
      // navigate('/cart')

  }
  useEffect(() => {
    dispatch(GetSingleProduct(id)).then((res) => {
      console.log(res, 'res in detail page');
    })

  }, [dispatch]);
  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);
  return (
    <div className="shopDetailPage">
      <div className="shopDetailWrapper container">
        <div className="shopDetailRow row justify-content-center">
          <div className="col-lg-6">
            <div className="spSlider">
              <div className="spSliderWrap">
                <div className="SpsliderLeft">
                  <Slider
                    asNavFor={nav1}
                    ref={slider2}
                    slidesToShow={SingleProduct?.product?.product_image?.length}
                    swipeToSlide={true}
                    focusOnSelect={true}
                  >
                    {SingleProduct?.product?.product_image?.map((image) => (


                      <div>
                        <div className="navThumb">
                          <img src={image.image} alt="" />
                        </div>
                      </div>


                    ))}
                  </Slider>

                </div>
                <div className="spSliderRt">
                  {SingleProduct?.product ?
                    <Slider className="mainSlider" asNavFor={nav2} ref={slider1}>
                      {SingleProduct?.product?.product_image?.map((image) => (


                        <div>

                          <div className="itemProd">
                            <div className="productBig">
                              <img src={image.image} alt="" />
                            </div>
                          </div>

                        </div>



                      ))}
                    </Slider> : <Skeleton height={"300px"} width={'100%'} />}
                </div>
              </div>
            </div>
          </div>
                    
          <div className="col-lg-6">
            <div className="prodDetailDec">
              <span> {loading||SingleProduct?.product?.brand ? SingleProduct?.product?.brand : <Skeleton />} </span>
              <h1>{SingleProduct?.product?.title ? SingleProduct?.product?.title : <Skeleton />}</h1>
              <div class="review">
                <span><i class="fa fa-star" aria-hidden="true"></i> {SingleProduct?.product?.rating_avg} <span class="r-count">Reviews ({SingleProduct?.product?.review?.length})</span></span>
              </div>
              <div className="price">
                <h2>{SingleProduct?.product?.unit_price ? `$ ${SingleProduct?.product?.unit_price}` : <Skeleton />}</h2>
              </div>
              <div className="prodSingleDesc">
                <h3>Product Description</h3>
                <div className="psdin">
                  <div className="psdRow">
                    <span>Model Number </span>
                    <span>{SingleProduct?.product?.code ? SingleProduct?.product?.code : <Skeleton />} </span>
                  </div>
                  <div className="psdRow">
                    <span>Height </span>
                    <span>{SingleProduct?.product?.height ? SingleProduct?.product?.height : <Skeleton />} </span>
                  </div>
                  <div className="psdRow">
                    <span>Weight </span>
                    <span>{SingleProduct?.product?.weight ? SingleProduct?.product?.weight : <Skeleton />} </span>
                  </div>
                  <div className="psdRow">
                    <span>Width </span>
                    <span>{SingleProduct?.product?.width ? SingleProduct?.product?.width : <Skeleton />}</span>
                  </div>
                </div>
              </div>
              {viewCart && 
              <div className="alertNote">
              <span><img src={checked} alt="" /> Your item has been successfully added to cart</span>
            </div>}
              
              <div className="sppageBtns">
                {/* <Link to="/cart" > */}
                <button  onClick={() => handleAddCart({id:SingleProduct?.product?.id,stock:SingleProduct?.product?.stock})} className="addTocartBtn">{viewCart? 'VIEW CART':'ADD TO CART'} </button>
                {/* </Link> */}
                <button onClick={()=>message.info('this feature will be done in next sprint')} className="buyNowBtn">BUY NOW</button> 
              </div>
            </div>
          </div>
        </div>
        <div className="wrapForReview">
          <CustomerReview singleCar={SingleProduct?.product?.review} />
        </div>
      </div>
    </div>
  );
}

export default ShopDetailView;

import { Skeleton } from 'antd';
import Cookies from 'js-cookie';
import React, { useEffect, useRef } from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import { GetCars } from '../Redux/Features/Dataslice';
function CarSlider() {
    const {data,loading}=useSelector(state=>state.Data)
    const user = Cookies.get('token')
    const dispatch = useDispatch()
    const navigate=useNavigate()
    useEffect(() => {
      dispatch(GetCars())
      
    }, [dispatch])
    //const [current, setCurrent] = useState(0);
    const sliderRef = useRef(null);
    console.log(sliderRef.current);
    var settings = {
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 2,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            dots: false
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
      };
      const filteredImage = data.filter((i)=>i.vehicle_image.map((j)=> j.thumbnail === true))
      console.log('filtered image',filteredImage);
  return (
    <div className="container">
        <div className="carSlider">
        <div className="carSlHd">
            <div className="carslHdL">
                <h2>Book Cars in and around New York City!</h2>
            </div>
            <div className="carslhdR">
                <span className="leftSl" onClick={()=>sliderRef.current.slickPrev()}><i className='fa fa-angle-left'></i></span>
                <span className="rightSl" onClick={()=>sliderRef.current.slickNext()}><i className='fa fa-angle-right'></i></span>
            </div>
        </div>
        <Slider {...settings} ref={sliderRef}>
            {
                loading && [...Array(4)].map((_, i) => (
                    <div key={i} style={{ width: '250px', margin: '60px', display:'flex' ,flexDirection:'column' }}>
                        <Skeleton title round loading active avatar />
                    </div>
                ))
            }
            
            {
                data && data.map((i)=>(
                    <div className="carSlItem">
                <div className="carSlIn">

                    
                <LazyLoadImage
                alt={i.name}
                effect="blur"
                width={250}
                height={100}
                src={
                    i.vehicle_image[0].image
                }
              />
                    {/* <img src={i.vehicle_image[0].image} alt="" /> */}
                </div>
                <div className="carSlDec">
                    <h2>{i.name}</h2>
                    <div className="review">
                        <span><i class="fa fa-star" aria-hidden="true"></i> {i.rating_avg} </span>
                        <span class="r-count">Reviews ({i.rating_count})</span>
                    </div>
                    {/* <Link to={user ?`/car-detail/${i.id}` : '/login' } > */}
                        <button onClick={()=> user ? navigate(`/car-detail/${i.id}`):navigate('/login',{state:{path:i.id}})} className='pickBtn'>PICK THIS CAR (${i.rate}/<span className='smallH'>h</span>)</button>
                        {/* </Link>  */}
                </div>
            </div>

                ))
            }
            {/* <div className="carSlItem">
                <div className="carSlIn">
                    <img src={Car3} alt="" />
                </div>
                <div className="carSlDec">
                    <h2>Lamborghini Aventador</h2>
                    <div className="review">
                        <span><i class="fa fa-star" aria-hidden="true"></i> 4.9 </span>
                        <span class="r-count">Reviews (254)</span>
                    </div>

                    <Link to="/login" ><button className='pickBtn'>PICK THIS CAR ($300.00/h)</button></Link> 
                </div>
            </div>
            <div className="carSlItem">
                <div className="carSlIn">
                    <img src={Car1} alt="" />
                </div>
                <div className="carSlDec">
                    <h2>Lamborghini Aventador</h2>
                    <div className="review">
                        <span><i class="fa fa-star" aria-hidden="true"></i> 4.9 </span>
                        <span class="r-count">Reviews (254)</span>
                    </div>
                    <Link to="/login" ><button className='pickBtn'>PICK THIS CAR ($300.00/h)</button></Link> 
                </div>
            </div>
            <div className="carSlItem">
                <div className="carSlIn">
                    <img src={Car1} alt="" />
                </div>
                <div className="carSlDec">
                    <h2>Lamborghini Aventador</h2>
                    <div className="review">
                        <span><i class="fa fa-star" aria-hidden="true"></i> 4.9 </span>
                        <span class="r-count">Reviews (254)</span>
                    </div>
                    <Link to="/login" ><button className='pickBtn'>PICK THIS CAR ($300.00/h)</button></Link> 
                </div>
            </div>
            <div className="carSlItem">
                <div className="carSlIn">
                    <img src={Car1} alt="" />
                </div>
                <div className="carSlDec">
                    <h2>Lamborghini Aventador</h2>
                    <div className="review">
                        <span><i class="fa fa-star" aria-hidden="true"></i> 4.9 </span>
                        <span class="r-count">Reviews (254)</span>
                    </div>
                    <Link to="/login" ><button className='pickBtn'>PICK THIS CAR ($300.00/h)</button></Link> 
                </div>
            </div> */}
        </Slider>
    </div>
    </div>
  );
}

export default CarSlider;

import React from 'react';
import { Button, Modal} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function SupportPopUp({state,handleSupportClose,support,Ride_id}) {
const navigate =useNavigate()
console.log('Ride_id is',Ride_id);
  //arrow function for handleclick
  const handleClick = () => {
    navigate('/contact-us',{state:{item:state.item,subject:'Cancel Ride',Ride_id}})
    // handleSupportClose();
  }
  return (
    <div>
        <Modal  show={support} onHide={handleSupportClose} className='modalDuration'>
      
        <Modal.Body>
          <div className="suppPop">
            <div className='suppCon'>
                <span>Please contact Revup support team to reschedule the appointment</span>
            </div>
            <Button onClick={handleClick} className='supportBtn'>
                CONTACT SUPPORT
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SupportPopUp;

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import AddNewAddress from './AddNewAddress';
import { DeleteShippingAddres, GetAllShippingAddress } from '../Redux/Features/Userslice';
import { message, Popconfirm } from 'antd';


function Shipping() {
    const { shippingAddress } = useSelector(state => state.User)
    const [extraClass, setExtraClass] = useState(1);
    const [addAdress, setAddAdress] = useState(false);
    const [refetch, setRefetch] = useState(false);
    const dispatch = useDispatch()

    const invertClass = (e) => {

        setExtraClass(e);
    };
    const handleClick = () => {
        //alert("test");
        setAddAdress(!addAdress)
    }

    
    function confirm(e) {
        dispatch(DeleteShippingAddres(e)).then((res) => {

            if (res.payload.message === "Deleted") {
                message.success('Address deleted successfully')
                setRefetch(true)
                setRefetch(false)
            }
        })
        
      }
      
      function cancel(e) {
        
      }

    useEffect(() => {

        dispatch(GetAllShippingAddress()).then((res) => {
            console.log(res);
        })
    }, [dispatch, refetch])
    // shippingAddress &&console.log(shippingAddress.shipping_addresses[0].id, 'shippingAddress id');
    return (
        <div>
            <div className="container">
                <div className="row rowPay">
                    <div className="paymt_wraper p-0">

                        {!addAdress &&
                            <>
                                <h1>{shippingAddress?.length}</h1>
                                {shippingAddress?.count > 0 ?
                                    shippingAddress?.shipping_addresses.map((address) => (
                                        <>

                                            <div onClick={() => invertClass(address.id)} className="paymt payType-item">
                                                <div className="checked">
                                                    <input type="radio" name="q1" id={address.id} value="1" onChange={() => invertClass(address.id)} checked={extraClass === address.id} />
                                                    <div className="liwLeft">
                                                        <span className="checkmark"></span>
                                                    </div>
                                                </div>
                                                <label className={"paymt " + (extraClass === address.id ? "active" : "")} for="shpp1">
                                                    <div className="paymt_in">
                                                        <div className="shipping_name">
                                                            <h2>{address.name}</h2>
                                                            <p>{address.address} , {address.city} ,{address.zip}</p>
                                                        </div>
                                                        <div className="shppg_in_btm">
                                                            <div className="edit">
                                                                <span>
                                                                    Edit
                                                                </span>
                                                            </div>
                                                            <div className="remove">
                                                                <Popconfirm
                                                                    title="Are you sure to delete this Shipping Address?"
                                                                    onConfirm={()=>confirm(address.id)}
                                                                    onCancel={cancel}
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                >
                                                                    <span   >
                                                                    Remove
                                                                </span>
                                                                </Popconfirm>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>

                                        </>

                                    ))
                                    : <h1>No Shipping Address Found</h1>


                                }

                            </>


                        }

                        {/* 1 */}


                    </div>
                    {!addAdress &&

                        <div className="addPayment">
                            <div onClick={handleClick} class="addCardBtn"><button class="acBtn"><span>+</span> ADD NEW ADDRESS</button></div>
                        </div>
                    }

                </div>
            </div>

            {addAdress && <AddNewAddress setAddAdress={setAddAdress} />}
        </div>
    );
}

export default Shipping;
import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import BookCabCta from '../Componants/BookCabCta';
import BookNow from '../Componants/BookNow';
import CustomerReview from '../Componants/CustomerReview';
import Footer from '../Componants/Footer';
import GadgetCta from '../Componants/GadgetCta';
import NavBar from '../Componants/NavBar';
import SingleProductBanner from '../Componants/SingleProductBanner';
import Specifications from '../Componants/Specifications';

function CarDetails() {
    const{id}= useParams()
    // const {singleCar}=useSelector(state=>state.Data)
    const [singleCar, setsingleCar] = useState({})

   
    const dispatch = useDispatch()
   
    useEffect(() => {

      axios.get(`https://staging-api.revupnyc.com/vehicle/${id}?populate=%20%5B%22vehicle_image%22%2C%22review%22%2C%20%22review.user%22%5D`,

      {headers:{Authorization:`Bearer ${Cookies.get('token')}`}})
  .then(function (response) {
    // handle success
    console.log(response.data.data);
    setsingleCar(response.data.data.vehicle)
  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  
    //  id &&Cookies.get('token')&& dispatch(GetCarById(id))
    //  const getDetails=async()=>{
       
    //    await dispatch(GetCarById(id)).then((response)=>{
    //     console.log('responseee',response.payload);
    //     setsingleCar(response.payload)
    //   })
      
      
       
    // } 
    // getDetails()
    }, [id,dispatch])
    
    // console.log(singleCar);
    
    
  return (
    <div>
        <NavBar />
        <SingleProductBanner />
         <BookNow singleCar={singleCar} />
        <Specifications singleCar={singleCar} />
        <div className="description">
            <div className="container">
                <div className="decHd">
                    <h2>Description</h2>
                </div>
                <div className="decCon">
                    <p>{singleCar && singleCar.description}</p>
                </div>
            </div>
        </div>
        <CustomerReview singleCar={singleCar.review} />
        <BookCabCta noBg/>
        <GadgetCta />
        <Footer />
    </div>
  );
}

export default CarDetails;

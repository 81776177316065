import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SpBanner from '../images/lamborgini.png';
import regBg from '../images/regImg.png';
import Footer from '../Componants/Footer'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ForgotPassword } from '../Redux/Features/Authslice';
import { message } from 'antd';
function ForgotPasswordPage() {
    const { register, handleSubmit, formState: { errors } } = useForm()
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const onSubmit = (data) => {
        console.log(data)
        dispatch(ForgotPassword(data)).then((res) => {
            // console.log(res,'response after forgot password');

            if (res.type === "auth/Forgotpassword/fulfilled") {
                message.success('Verification code send successfully')
                navigate('/email-verification', { state: { data: res?.payload?.data.session_id } })
            } else {
                message.info(res?.payload?.response?.data?.message)
            }
        })
    }
    return (
        <div>
            <div className="bannerSec spBanner" style={{ backgroundImage: `url('${SpBanner}')` }}>
                <div className="WrapperBnr">

                </div>
            </div>
            <div className="accRegistration">
                <div className="container">
                    <div className="row accRow">
                        <div className="col-lg-6 colRgLeft p-0">
                            <div className="rgLeftWrap">
                                <div className="rgLcon h100">
                                    <div className="rgLHd">
                                        <h2><span>Enjoy the </span> <span>Experience with us!</span></h2>
                                    </div>
                                    <div className="rgLimg">
                                        <img src={regBg} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 colRgRight p-0">
                            <div className="rgRtWrap formstructure">
                                <div className="formBody">
                                    <div className="rgRtHd">
                                        <h2>Forgot Password ?</h2>
                                        <span>Enter your registered email address</span>
                                    </div>
                                    <div action="#" class="register-form">
                                        <form onSubmit={handleSubmit(onSubmit)} >
                                            <div className="product--name epDetail">
                                                <div className="epForm register-form">
                                                    <div className="form-controller">
                                                        <label htmlFor="">Email</label>
                                                        <input type="email" name="email" placeholder="Email"  {...register('email', {
                                                            required: 'E-mail is required',
                                                            pattern: {
                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                message: "invalid email address"
                                                            }
                                                        })} />
                                                        {errors?.email && <p style={{ color: 'red' }}>{errors?.email.message}</p>}
                                                    </div>
                                                    <button type="submit" class="btn btn-save">Next</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="register-form">
                                    <p class="message">Don't have an account? <Link to='/user-info'><a href="#">Register</a></Link> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    );

}

export default ForgotPasswordPage;

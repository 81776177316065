import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import Skeleton from 'react-loading-skeleton'
import { GetAllProducts } from '../Redux/Features/Shopslice';
function ShopView() {
    const dispatch = useDispatch()
    const [page,setpage]=useState(1)
    const [price,setprice]=useState('new')
    
    const [productCount,setproductCount]=useState(1)
    const { Products } = useSelector(state => state.Shop)
    const navigate=useNavigate()
    const handlePageClick = async (data) => {
        alert(data)
        setpage(data)
       dispatch(GetAllProducts({price,data}))

   }

    useEffect(() => {
        dispatch(GetAllProducts({price,page})).then((res) => {
            setproductCount(res.payload.count, 'shop');
        })
        setpage(0)
    }, [dispatch,price])
    // console.log(Products,'products');
    return (
        <div className='shopWrapper'>
            <div className="container">
                <div className="shopRow">
                    <div className="shopHd">
                        <div className="shopHdLeft">
                            <h2>All Products</h2>
                        </div>
                        <div className="shopHdRt">
                            <div className="shopRtItem">
                                <span> <i class="fad fa-sort-alt"></i> Sort By</span>
                                <div class="customs_selects">
                                    
                                    <select onChange={(e)=>setprice(e.target.value)} name="product" class="customs_sel_box">
                                        
                                        <option value="low">low to high</option>
                                        <option value="high">high to low</option>
                                    </select>
                                </div>
                            </div>
                            <div className="shopRtItem">
                                <span> <i class="fad fa-shopping-bag"></i> Product</span>
                                <div class="customs_selects">
                                    <select onChange={(e)=>setprice(e.target.value)} name="product" class="customs_sel_box">
                                        
                                        <option value="new">newly added first</option>
                                        <option value="old">newly added last</option>
                                        
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shopList row">

                        { Products?.products?   Products?.products?.map((item) => (
                            <>
                                <div className="col-lg-3 col-sm-6 col-12 p-0">
                                    
                                        <div onClick={() => navigate(`/shop-detail/${item.id}`)} className="productItem linkTo">
                                            <div className="prodThumb">
                                                <img src={item.product_image[0].image} alt="" />
                                            </div>
                                            <div className="prodDetail">
                                                <div class="review">
                                                    <span><i class="fa fa-star" aria-hidden="true"></i> {item?.rating_avg}</span>
                                                </div>
                                                <h2 className='prodName'>{item.title} {item.brand}</h2>
                                                <span className='prodPrice'>${item.unit_price}</span>
                                            </div>
                                        </div>
                                    
                                </div>
                            </>
                            
                            
                        )): 

                        <>

                        {
                            [...Array(8)].map((_,i)=>(
                                <>
                                <div className="col-lg-3 col-sm-6 col-12 p-0">
                                    
                                    <div  className="productItem linkTo">
                                        <div className="prodThumb">
                                        <Skeleton height={100} width={100} /> 
                                        </div>
                                        <div className="prodDetail">
                                            <div class="review">
                                                <span><Skeleton/></span>
                                            </div>
                                            <h2 className='prodName'><Skeleton/> <Skeleton/> </h2>
                                            <span className='prodPrice'><Skeleton/> </span>
                                        </div>
                                    </div>
                                
                            </div>
                                </>

                            ))
                        }
                                
                            </>
                        
                        
                        
                        
                        }


                    </div>
                    <div className="shopPagination">
                        <div className="paginationRow">
                            <div className="page">
                                {[...Array(Math.ceil(productCount / 8))].map((_, i) => (
                                    <>
                                        <span onClick={() =>handlePageClick(i )} className={i === page ? "page-item active" : 'page-item'}>
                                            {i + 1}
                                        </span>
                                    </>
                                ))}

                            </div>
                        </div>
                        {/* <ReactPaginate
          pageCount={Math.ceil(21/8)  }
          onPageChange={handlePageClick}
          containerClassName={'pagination justify-content-center '}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousClassName={'page-item'}
          previousLinkClassName={'page-link'}
          nextClassName={'page-item'}
          nextLinkClassName={'page-link'}
          breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
          activeClassName={'active'}
        /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShopView;

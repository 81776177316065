import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import remvicn from '../images/offers.png';
import { useLocation } from 'react-router-dom'
import { ApplyCoupencode, BookRide, RemoveCoupencode } from '../Redux/Features/Dataslice';
import { Skeleton, message } from 'antd';
function ConfirmBookingView() {

    const { bookingDetails, coupenerror, coupenOffer, loading, CoupenRemove, error } = useSelector(state => state.Data)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [code, setcode] = useState()
    const [itm, setitm] = useState(false)
    const [coupenData, setcoupenData] = useState({})
    const reFetch = sessionStorage.getItem('reFetch')

    const { state } = useLocation()
    const { datetime, time, distance, id, locationId } = state
    console.log(datetime, time, distance, id, locationId);
    const item = sessionStorage.getItem('reFetch')
    console.log('item', item);
    //checking line

console.log(coupenOffer,'coupenOffer is are');

    useEffect(() => {
        datetime && time && distance && id && locationId && dispatch(BookRide({ datetime, time, distance, id, locationId }))
    }, [dispatch])

    return (
        <div>


            <div className="confirmBooking">

                <div className="container">
                    <div className="row cbRow">
                        <div className="col-lg-6">
                            <div className="cbLeft h100">
                                <h2>Confirm Your Booking</h2>
                                <div className="cbLcar h100">
                                    <img src={bookingDetails?.ride_details?.vehicle?.image} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            {!bookingDetails?.ride_details?.vehicle?.name ? <Skeleton /> :

                                <div className="cbRight">
                                    <div className="cbRHd">
                                        <span>{bookingDetails?.ride_details?.vehicle?.plate_number} </span>
                                        <h2>{bookingDetails?.ride_details?.vehicle?.name}</h2>
                                    </div>
                                    <ul className='bookingDetail'>
                                        <li className='bdItem'>
                                            <div className="bdLeft">
                                                <span>Pick Up:  </span>
                                            </div>
                                            <div className="bdRight">
                                                <div className="bdRicon">
                                                    <i className='fad fa-map-marker-alt'></i>
                                                </div>
                                                <div className="optedItem">
                                                    <span>{bookingDetails?.ride_details?.pickup_location?.title}</span>
                                                    <h3>{bookingDetails?.ride_details?.pickup_location?.location}</h3>
                                                </div>
                                            </div>
                                        </li>
                                        <li className='bdItem'>
                                            <div className="bdLeft">
                                                <span>Start Trip:</span>
                                            </div>
                                            <div className="bdRight">
                                                <div className="bdRicon">
                                                    <span className='dot'>
                                                    </span>
                                                </div>
                                                <div className="optedItem">
                                                    <h3>{bookingDetails?.ride_details?.start.slice(0, 6)} </h3>
                                                    <span>{bookingDetails?.ride_details?.start.slice(7)} </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li className='bdItem'>
                                            <div className="bdLeft">
                                                <span>End Trip:</span>
                                            </div>
                                            <div className="bdRight">
                                                <div className="bdRicon">
                                                    <span className='dot withWhite'>

                                                    </span>
                                                </div>
                                                <div className="optedItem">
                                                    <h3>{bookingDetails?.ride_details?.end.slice(0, 6)} </h3>
                                                    <span>{bookingDetails?.ride_details?.end.slice(7)} </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="totalHrs">
                                        <h3> <i class="fas fa-stopwatch"></i> {bookingDetails?.ride_details?.duration !== 1 ? `${bookingDetails?.ride_details?.duration} hours` : `${bookingDetails?.ride_details?.duration} Hour`}  </h3>
                                        <p>* A Maximum of 30 minutes of delay time can be expected based on the traffic.</p>
                                    </div>
                                </div>



                            }

                        </div>
                    </div>
                    <div className="row calcRow">
                        <div className="col-lg-6">
                            <div className="pickLocation">
                                <span className="plSpan">
                                    <i className='fad fa-map-marker-alt'></i> Pickup Location
                                </span>
                                <div className="locName">
                                    <span>{bookingDetails?.ride_details?.pickup_location?.title}</span>
                                    <h2>{bookingDetails?.ride_details?.pickup_location?.location}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="calcPart">
                                {!sessionStorage.getItem('reFetch') && <span>Do you Have Promo Code?</span>}







                                {coupenOffer.offer && sessionStorage.getItem('reFetch') ? <div className="promoApplied">
                                    <div className="paLeft">
                                        <div className="iconpa">
                                            <img src={remvicn} alt="" />
                                        </div>
                                        <div className="patext">
                                            { coupenOffer.type === 'price_discount' ? <span>promo code Applied</span> :<span>You have got a {coupenOffer?.offer} <br/>  you will get it during the time of service</span> }
                                            {/* <span>{coupenOffer.type === 'price_discount' ? 'Promocode applied' : `You have got a ${coupenOffer?.offer}  you will get it during the time of service`}</span> */}
                                        </div>
                                    </div>
                                    <div className="paRt">
                                        <div className="paremove">
                                            {coupenOffer ? <span onClick={() => dispatch(RemoveCoupencode({ discount: coupenOffer?.offer, price: coupenOffer.total_cost })).then((res) => {
                                                console.log('Remove coupencode', res);
                                                if (res.type === 'data/Removecoupen/fulfilled') {
                                                    message.info('Promo code removed successfully')
                                                    sessionStorage.removeItem('reFetch')
                                                    setitm(false)

                                                    setcoupenData(res.payload)


                                                }
                                                console.log(coupenData, 'coupendata');

                                            })} >Remove</span>
                                            
                                            :
                                            <>
                                            
                                            </>
                                             }

                                        </div>
                                    </div>
                                </div> : <div className="promoInput">
                                    <input value={code} onChange={(e) => setcode(e.target.value)} type="text" placeholder='Enter Promo Code' />
                                    <button disabled={loading} onClick={() => !code ? message.info('Enter Promo Code') : dispatch(ApplyCoupencode({ code, price: bookingDetails?.ride_details?.total_cost }))


                                        .then((res) => {


                                            if (res.type !== 'data/Applycoupen/rejected') {
                                                sessionStorage.setItem('reFetch', true)
                                                message.info('Promo code applied successfully')
                                                console.log(sessionStorage.getItem('reFetch'), 'under log');
                                                setitm(true)

                                            }
                                        }

                                        )
                                    } >APPLY</button>

                                </div>}




                                {coupenerror && <h6>{coupenerror}</h6>}
                                <div className="orderSum">
                                    <h2>Order Summary</h2>
                                    <div className="orders">
                                        <div className="orderin">
                                            <div className="ordItemName">
                                                <span>Ride Cost (${bookingDetails?.ride_details?.vehicle?.rate}/Hour) {bookingDetails?.ride_details?.vehicle?.peak_day && <i style={{ color: '#F6491E', fontSize: 'initial' }} class="fa fa-exclamation-circle carAlert" aria-hidden="true"></i>} </span>
                                            </div>
                                            <div className="itemPrice actualPrice">
                                                <span>${bookingDetails?.ride_details?.ride_cost}</span>
                                            </div>
                                        </div>
                                        <div className="orderin">
                                            <div className="ordItemName">
                                                <span>Service Charge </span>
                                            </div>
                                            <div className="itemPrice">
                                                <span>${bookingDetails?.ride_details?.service_charge}</span>
                                            </div>
                                        </div>
                                        {

                                            sessionStorage.getItem('reFetch') && coupenOffer.type === 'price_discount' &&
                                            <div className="orderin">
                                                <div className="ordItemName">
                                                    <span>Coupon Code Applied </span>


                                                </div>
                                                <div className="itemPrice">
                                                    <span>- {coupenOffer && coupenOffer?.offer}</span>
                                                </div>
                                            </div>


                                        }


                                        <div className="orderin totalPrice">
                                            <div className="ordItemName">
                                                <span>TOTAL</span>
                                            </div>
                                            <div className="itemPrice">
                                                {/* <span>$ */}
                                                <span>$  {sessionStorage.getItem('reFetch') && coupenOffer.total_cost ? coupenOffer.total_cost : bookingDetails?.ride_details?.total_cost}</span>
                                                {/* {coupenOffer.total_cost ? coupenOffer.total_cost : bookingDetails?.ride_details?.total_cost}</span> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="proceedBtn">
                                        <button onClick={() => navigate('/add-payment', { state: { Ridecost: bookingDetails?.ride_details?.ride_cost, serviceCharge: bookingDetails?.ride_details?.service_charge, offer: coupenOffer && sessionStorage.getItem('reFetch') && coupenOffer?.offer, total: sessionStorage.getItem('reFetch') && coupenOffer.total_cost ? coupenOffer.total_cost : bookingDetails?.ride_details?.total_cost, product_image: bookingDetails?.ride_details?.vehicle?.image, vehicle_name: bookingDetails?.ride_details?.vehicle?.name, number_plate: bookingDetails?.ride_details?.vehicle?.plate_number, location_title: bookingDetails?.ride_details?.pickup_location?.title, location_spot: bookingDetails?.ride_details?.pickup_location?.location, duration: bookingDetails?.ride_details?.duration, start: bookingDetails?.ride_details?.start, end: bookingDetails?.ride_details?.end, vehicle_rate: bookingDetails?.ride_details?.vehicle?.rate, vehicle_id: bookingDetails?.ride_details?.vehicle_id, location_id: bookingDetails?.ride_details?.location_id, coupen_id: coupenOffer?.id, start_at: bookingDetails?.ride_details?.start_at, ride_date: bookingDetails?.ride_details?.ride_date, offer_type: coupenOffer?.type, peak_day: bookingDetails?.ride_details?.vehicle?.peak_day,product_Offer:coupenOffer } })} >PROCEED</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ConfirmBookingView;

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Profile from '../images/prof.jpeg'
import { ClearUserNofification, GetAllUserNofification } from '../Redux/Features/Userslice'

function NotificationView() {
const dispatch =useDispatch()
const {notifications}=useSelector(state=>state.User)
    useEffect(() => {
      dispatch(GetAllUserNofification())
    }, [dispatch])
    console.log(notifications);
  return (
    <div>
        <div className="notificationView">
            <div className="container">
               <div className="nvWrap">
                    <div className="nvhd">
                        <div className="allRead">
                            <span onClick={()=>dispatch(ClearUserNofification())} >Clear</span>
                        </div>
                    </div>
                    {notifications?.user_notifications?.length > 0 ? notifications?.user_notifications?.map((i)=>(
                        
                        <>
                        <div className="nvRow">
                        <div className="nvIcon">
                            <div className="nvImg">
                                <img src={i?.image} alt="" />
                            </div>
                        </div>
                        <div className="nvDec">
                            <h2   >{i?.description}</h2>
                            <span    className='nvId'># 21252265</span>
                            <span  className='nvTime'>{i.duration}</span>
                        </div>
                    </div>
                    </>
                    )): <h1>No Notification Found</h1>}
                    
                    {/* <div className="nvRow">
                        <div className="nvIcon">
                            <div className="nvImg">
                                <img src={Profile} alt="" />
                            </div>
                        </div>
                        <div className="nvDec">
                            <h2>Driver has been assigned to your ride</h2>
                            <span className='nvId'># 21252265</span>
                            <span className='nvTime'>2 min ago</span>
                        </div>
                    </div>
                    <div className="nvRow">
                        <div className="nvIcon">
                            <div className="nvImg">
                                <img src={Profile} alt="" />
                            </div>
                        </div>
                        <div className="nvDec">
                            <h2>Driver has been assigned to your ride</h2>
                            <span className='nvId'># 21252265</span>
                            <span className='nvTime'>2 min ago</span>
                        </div>
                    </div>
                    <div className="nvRow">
                        <div className="nvIcon">
                            <div className="nvImg">
                                <img src={Profile} alt="" />
                            </div>
                        </div>
                        <div className="nvDec">
                            <h2>Driver has been assigned to your ride</h2>
                            <span className='nvId'># 21252265</span>
                            <span className='nvTime'>2 min ago</span>
                        </div>
                    </div> */}
               </div>
            </div>
        </div>
    </div>
  );
}

export default NotificationView;
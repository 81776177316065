import { configureStore } from '@reduxjs/toolkit'
import DataReducer from './Features/Dataslice'
import AuthReducer from './Features/Authslice'
import CardReducer from './Features/Cardslice'
import ReviewReducer from './Features/Reviewslice'
import UserReducer from './Features/Userslice'
import ShopReducer from './Features/Shopslice'
export const store = configureStore({
  reducer: {
    Data:DataReducer,
    Auth:AuthReducer,
    Card:CardReducer,
    Review:ReviewReducer,
    User:UserReducer,
    Shop:ShopReducer
    
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
})
import { CardCvcElement, CardExpiryElement, CardNumberElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { message } from 'antd';
import React, { useState } from 'react'
import { Button, Modal,Form  } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Cards from '../images/cards.png'
import { AddNewCard } from '../Redux/Features/Cardslice';
import { toast, ToastContainer } from 'react-toastify';

const stripePromise = loadStripe('pk_test_ZhcC8ZgxbuaVnZ5T9wrISWUx00Xl80CLQR');
function AddPayment({handleAddCardClose,addCard,setrefetchcard}) {
  const options = {
    // passing the client secret obtained from the server
    clientSecret: 'pk_test_51KVu5xSAw1L3e61ZdB9X99ecpVyRng0of3WajbVAsNASA1kNEf9KpAm2tMUDJuxnVYU90rT1Yr32cUbT68QGe1iG00EyW4BCKg',
  };
  return (
    <>
    <Elements stripe={stripePromise} >
   < MyComponent handleAddCardClose={handleAddCardClose} addCard={addCard} setrefetchcard={setrefetchcard} />
   </Elements>
    </>
  );
}

export default AddPayment;


const MyComponent = ({handleAddCardClose,addCard,setrefetchcard}) => {
  // console.log('props',props);
  // const stripe = useStripe();
  // const elements = useElements();

  // rest of the component
  // console.log('stripe',stripe);
  // console.log('elements',elements);

  // const handleSubmit=async (e)=>{
  //    e.preventDefault()
  //    const cardElement = elements.getElement(CardNumberElement);
  //   //  console.log(cardElement);
  //     await stripe.createToken(cardElement,{name:'Marcus'}).then((res)=>{
  //       console.log(res);
  //       res.token ? message.success('card added successfully'):
  //       message.info(res.error.message);
  //     })
  //   //  error && console.log(error);
  //   //  paymentMethod&& console.log(paymentMethod);
  // }
  const [nameOnCard, setnameOnCard] = useState('')
  const [load, setload] = useState(false)
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch()
  const {loading}=useSelector(state=>state.Card)
  const handleSubmit =async(e)=>{
    setload(true)
    e.preventDefault()
    setload(true)
    
    const cardElement = elements.getElement(CardNumberElement);
   //  console.log(cardElement);
     await stripe.createToken(cardElement,{name:nameOnCard}).then((res)=>{
       console.log(res);

       res.token ? dispatch(AddNewCard(res.token.id)).then((result)=>{
         
         if (result.payload?.message === 'Ok') {
          handleAddCardClose()
          setload(false)
          message.success('Card added successfully ')
          setrefetchcard(true)
          setrefetchcard(false)
         }else{
          message.info(result.payload);
         }
       }):
      //  message.info( {
      //   content:  res.error.message,
      //   className: 'custom-class',
      //   style: {
      //     marginTop: '28vh',
      //   },
      // }     );
      message.info(res.error.message)
       setload(false)
     })
  }

  return(
//     <Modal show={addCard} onHide={handleAddCardClose} className='modalDuration'>
//     <Modal.Header closeButton className='border-0'>
//       <Modal.Title>Add a credit or debit card</Modal.Title>
//     </Modal.Header>
//     <Modal.Body>
//       <div className='chooseLocation'>
//         <div className="cardAccept mt-0">
//             <p>We accepts all major credit and debit cards:</p>
//             <div className="cards">
//                 <img src={Cards} alt="" />
//             </div>
//         </div>
//         <div className="row accRow contactRow">                    
//             <div className="col-lg-12 colRgRight p-0 align-self-center">
//                 <div className="rgRtWrap">
//                     <div action="#" class="register-form addCardFrm">
//                         <div className="formWrap FormRow frmCard row">
//                         <div className="form-controller col-lg-12">
//                                 <label htmlFor="">Name on card</label>
//                                 <input onChange={(e)=>setnameOnCard(e.target.value)} type="text" name="ln" />
//                             </div> 
//                             <div  className="form-controller col-lg-12">
//                                 <label htmlFor="">Card Number</label>
//                                 <div style={{border:'1px solid #E1E1E1 ',padding:'15px',width:'100%',backgroundColor:'#FCFCFC',borderRadius:'4px'}} >
//                                 <CardNumberElement />
//                                 </div>
                                
//                             </div>
                             
//                             <div className="form-controller col-lg-12">
//                                 <label htmlFor=""></label>
//                                 <div className="expDateSelect">
//                                     <div class="customs_selects">
//                                     <div style={{border:'1px solid #E1E1E1  ',padding:'15px',width:'100%',backgroundColor:'#FCFCFC',borderRadius:'4px'}} >
//                                     <CardExpiryElement />
//                                     </div>
//                                     </div>
//                                     <div class="customs_selects">
//                                     <div style={{border:'1px solid #E1E1E1 ',padding:'15px',width:'100%',backgroundColor:'#FCFCFC',borderRadius:'4px'}} >
                                      
//                                     <CardCvcElement />
//                                     </div>
//                                     </div>
//                                 </div>
//                             </div> 
                           
//                         </div>
//                     </div> 
//                 </div>
//             </div>
//         </div>
//       </div>
//     </Modal.Body>
//     <Modal.Footer className='border-0'>
//       <Button variant="secondary" onClick={handleAddCardClose} className='closeBtn'>
//         Cancel 
//       </Button>
//      {!load && !loading ? <Button disabled={load&&loading} variant="primary" onClick={handleSubmit} className='saveBtn'>
//         Done
//       </Button>:<Button  variant="danger">Loading ...</Button>} 
//     </Modal.Footer>
//     <ToastContainer
//                     position="top-center"
//                     autoClose={3300}
//                     hideProgressBar
//                     newestOnTop
//                     closeOnClick
//                     rtl={false}
//                     pauseOnFocusLoss
//                     draggable
//                     pauseOnHover
//                 />
//   </Modal>








 <div>
            <div class="rgRtWrap">
                <div class="rgRtHd addNwAdrsHd">
                    <h2>Add Payment</h2>
                </div>
                <div action="#" class="register-form addNewForm">
                    <form  >
                    <div className="row accRow contactRow">                    
            <div className="col-lg-12 colRgRight p-0 align-self-center">
                <div className="rgRtWrap">
                    <div action="#" class="register-form addCardFrm">
                        <div className="formWrap FormRow frmCard row">
                        
                            <div  className="form-controller col-lg-12">
                                <label htmlFor="">Card Number</label>
                                <div style={{border:'1px solid #E1E1E1 ',marginBottom:'10px',padding:'15px',width:'100%',backgroundColor:'#FCFCFC',borderRadius:'4px'}} >
                                <CardNumberElement />
                                </div>
                                
                            </div>

                            <div className="form-controller col-lg-12">
                                <label htmlFor="">Name on card</label>
                                
                                <input onChange={(e)=>setnameOnCard(e.target.value)} type="text" name="ln" placeholder='Card Holder Name' />
                            </div> 
                             
                            <div className="form-controller col-lg-12">
                                <label htmlFor=""></label>
                                <div className="expDateSelect">
                                
                                    <div class="customs_selects">
                                    <label>Expired on</label>
                                    <div style={{border:'1px solid #E1E1E1  ',padding:'15px',width:'100%',backgroundColor:'#FCFCFC',borderRadius:'4px'}} >
                                    <CardExpiryElement />
                                    </div>
                                    </div>
                                    <div class="customs_selects">
                                    <label>3-digit CVV</label>
                                    <div style={{border:'1px solid #E1E1E1 ',padding:'15px',width:'100%',backgroundColor:'#FCFCFC',borderRadius:'4px'}} >
                                      
                                    <CardCvcElement />
                                    </div>
                                    </div>
                                </div>
                            </div> 
                           
                        </div>
                    </div> 
                </div>
            </div>
        </div>

        {!load && !loading ? <Button disabled={load&&loading} style={{marginTop:'19px'}} onClick={handleSubmit} className='btn'>
        save
      </Button>:<Button style={{marginTop:'19px'}}>Loading ...</Button>} 

                       {/* <button type="submit" class="btn">Save</button> */}
                    </form>
                </div>
            </div>
        </div> 
  )
};
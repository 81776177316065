import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetLoggedinUser } from '../Redux/Features/Authslice';
import { CancelRide, ContactUs } from '../Redux/Features/Dataslice';
import ContactConfirmPopup from './Contactconfirmpopup';
import { useForm } from 'react-hook-form';
import { message } from 'antd';

function ContactView() {
    const { state } = useLocation()
    const { user } = useSelector(State => State.Auth)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [time, settime] = useState('Morning')
    const [wayTocontact, setwayTocontact] = useState("Phone only")
    const [subject, setsubject] = useState()
    const [rating, setRating] = useState()
    const [isError, setisError] = useState(false)
    const [confirmPopup, setconfirmPopup] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm()


    const onSubmit = (data) => {

        // console.log(data,'data');
        


    }
    const handleContact =( {name, email, phone_code, phone, time, wayTocontact, message})=>{

        console.log(name,email);
        dispatch(ContactUs( {name, email, phone_code, phone, time, wayTocontact, message} )).then((res) => {
            console.log(res, 'response is ');
            if (res.type === 'data/contactUs/fulfilled') {
                  setconfirmPopup(true)
                  state?.subject ? dispatch(CancelRide(state?.Ride_id)).then((res)=>{
                      console.log(res,'cancel ride');
                  }) : console.log('not cancelled');

                

            }
        })

    }
    //UseEffect for contact view
    useEffect(() => {
        dispatch(GetLoggedinUser())
    }, [dispatch])
console.log(state,'state');
    return (
        <div>
            <div className="accRegistration ContactUs">
                <div className="container">
                    <div className="row accRow contactRow">
                        <div className="col-lg-12 colRgRight p-0 align-self-center">
                            <div className="rgRtWrap">
                            <form onSubmit={handleSubmit(onSubmit)} >
                                <div action="#" class="register-form">
                                
                                    <div className="formWrap FormRow">
                                        <div className="form-controller col-lg-6">
                                            <label htmlFor="">Name</label>
                                            <input {...register('fn', { required:'fistname required'})}  type="text" defaultValue={user?.user?.full_name} name="fn" placeholder="First Name" />
                                            {errors?.fn && <p style={{ color: 'red' }}>{errors?.fn.message}</p>}
                                        </div>
                                        <div className="form-controller col-lg-6">
                                            <label htmlFor="">Email</label>
                                            <input {...register('email', { required:true})}  type="text" defaultValue={user?.user?.email} name="email" placeholder="Email" />
                                            
                                        </div>
                                        <div className="form-controller col-lg-6">
                                            <label htmlFor="">Phone</label>
                                            <input {...register('ln', { required:'phone is required'})}  defaultValue={user?.user?.phone} type="text" name="ln" placeholder="Phone" />
                                            {errors?.ln && <p style={{ color: 'red' }}>{errors?.ln.message}</p>}
                                        </div>
                                        <div className="form-controller col-lg-6">
                                            <label htmlFor="">Best time to contact</label>
                                            <div class="customs_selects">

                                            <select className='select-state' {...register("state_id", { required: 'select one State' })} name='state_id'>
                                    
                                    <>
                                    <option selected disabled hidden>Select</option>
                                    <option value="Morning">Morning</option>
                                                <option value="Afternoon">Afternoon</option>
                                                <option value="Evening">Evening</option>
                                                <option value="Anytime">Anytime</option>
                                    </>
                               
                            </select>
                            {errors?.state_id && <p style={{ color: 'red' }}>{errors?.state_id.message}</p>}
                                            </div>
                                        </div>
                                        <div className="form-controller col-lg-6">
                                            <label htmlFor="">Best way to contact</label>

                                            <div class="customs_selects">
                                                <select {...register("prod", { required: "select one option" })} onClick={(e) => setwayTocontact(e.target.value)} name="prod" class="customs_sel_box">
                                                <option selected disabled hidden>Select</option>
                                                    <option value="Phone only">Phone only</option>
                                                    <option value="Email only">Email only</option>
                                                    <option value="Both">Both</option>
                                                </select>
                                                {errors?.prod && <p style={{ color: 'red' }}>{errors?.prod.message}</p>}
                                            </div>
                                        </div>
                                        <div className="form-controller col-lg-6">
                                            <label htmlFor="">Subject</label>
                                            <input {...register('pw', { required:true})} defaultValue={state?.subject} type="text" name="pw" placeholder="Subject" />
                                            {errors?.pw && <p style={{ color: 'red' }}>{errors?.pw.message}</p>}
                                        </div>
                                        
                                        <div className="form-controller col-lg-12 p-0">
                                            <label htmlFor="">Message</label>
                                            <textarea {...register('msg', { required:"Message is required"})}  onChange={(e) => setsubject(e.target.value)} name="msg" id="" cols="30" >
                                            

                                            </textarea>
                                            {errors?.msg && <p style={{ color: 'red' }}>{errors?.msg.message}</p>}
                                        </div>
                                    {/* {isError &&  <span style={{color:'red'}} >message required</span>}  */}
                                    </div>
                                    <button onClick={() =>
                                    !subject? setisError(true):
                                    handleContact({ name: user?.user?.full_name, email: user?.user?.email, phone_code: user?.user?.phone_code, phone: user?.user?.phone, time: time, wayTocontact: wayTocontact, message: subject })
                                    } type="submit" class="btn contactBtn">SUBMIT</button>
                                    
                                    






                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {
            confirmPopup && <ContactConfirmPopup setconfirmPopup={setconfirmPopup} support={confirmPopup} />
        }
        </div>
    );
}

export default ContactView;

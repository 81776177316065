import { message } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {useNavigate} from 'react-router-dom'
import { UserPhoneVerify } from '../Redux/Features/Userslice'
import formatPhoneNumber from '../Tools/PhoneFormatter'
function PhoneEdit({user}) {
    const navigate =useNavigate()
    const dispatch=useDispatch()
    const [phone,setPhone]=useState()
    const PhoneNumber =formatPhoneNumber(user?.phone)
    const handleChange =(e)=>{
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        console.log(formattedPhoneNumber);
        setPhone(formattedPhoneNumber)
    }
    console.log(user,'formatted phone number');

    
    const verifyPhone =({phone_code,phone})=>{
        // console.log(phone,'phonecode,phone');
        dispatch(UserPhoneVerify({phone_code,phone})).then((res)=>{
            console.log(res,'res');
            if(res.type=== "user/userPhoneVerify/fulfilled" ){
                message.success('verification code sent  successfully')
                navigate('/verify-phone',{state:{phone:user?.user?.phone,data:res?.payload?.data?.session_id,case:''}})
            }
            
        })	
        
    }
  return (
    <div>
        <div className="EditWrp">
            <div className="EdtIn">
                <h2>Your phone number</h2>
                
                <span>{`(${user?.user?.phone.substring(0,3)})${user?.user?.phone.substring(3,6)}-${user?.user?.phone.substring(6,10)}`}</span>
                
            </div>
            {!user?.user?.phone_verified &&
            <>
            <div className="verify">
                <span style={{cursor:'pointer'}} onClick={()=>verifyPhone({phone_code:user?.user?.phone_code,phone:user?.user?.phone})} className='vfyTxt'>Verify Phone Number</span>
            </div>
            </>
            }
            
            
        </div>
        <input value={phone} onChange={handleChange}/>
            <button onClick={()=>navigate('/phone-change',{state:{phone:user?.user?.phone,phone_code:user?.user?.phone_code}})} className='editBtn'>CHANGE PHONE</button>
        
    </div>
  );
}

export default PhoneEdit;
import React, { useEffect, useState } from 'react';
import Rating from 'react-rating';
import BlueCar from '../images/car-blue.png';
import SpBanner from '../images/lamborgini.png'
import { ImLocation } from "react-icons/im";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RideDetailsById } from '../Redux/Features/Dataslice';
import { GetAllCards } from '../Redux/Features/Cardslice';
import { ReviewAndTip } from '../Redux/Features/Reviewslice';
import Skeleton from 'react-loading-skeleton';
import { message } from 'antd';
import moment from 'moment';
import { useForm } from 'react-hook-form';
function RideDetail5View() {
    const { state } = useLocation();
    const dispatch = useDispatch()
    const navigate=useNavigate()
    const { RideDetailsUser } = useSelector(State => State.Data)
    const [Review, setReview] = useState()
    const [Ammount, setAmmount] = useState()
    const [rating, setRating] = useState()
    const [cardId, setcardId] = useState()
    const [Custom, setCustom] = useState(false)
    const { register, handleSubmit, control, formState: { errors } } = useForm()


    const onSubmit = (data) => {

        console.log(data, 'sunD');
        Review&&rating? dispatch(ReviewAndTip({Review,Ammount,rating,cardId,driverId:RideDetailsUser?.driver_id,vehicleId:RideDetailsUser?.vehicle_id,rideId:RideDetailsUser?.id})).then((res)=>{
            console.log(res, 'res after adding review and tip');
            res.type=== "review/tip/fulfilled" && navigate('/my-rides')
        }) :message.info('Rating required')
        



    }
// const handleReviewAndTip =(account)=>{

    
//     if (account) {
//         Review&&rating? dispatch(ReviewAndTip({Review,Ammount,rating,cardId,driverId:RideDetailsUser?.driver_id,vehicleId:RideDetailsUser?.vehicle_id,rideId:RideDetailsUser?.id}))
//         .then((res)=>{
//             console.log(res, 'res after adding review and tip');
//             res.type=== "review/tip/fulfilled" && navigate('/my-rides')
//         }) :message.info('Please fill all the fields')
        
//     }else{
//         Review&&rating? dispatch(ReviewAndTip({Review,Ammount,rating,cardId,driverId:RideDetailsUser?.driver_id,vehicleId:RideDetailsUser?.vehicle_id,rideId:RideDetailsUser?.id})).then((res)=>{
//             console.log(res, 'res after adding review and tip');
//             res.type=== "review/tip/fulfilled" && navigate('/my-rides')
//         }) :message.info('Please fill all the fields')
//     }
// }

const handleClick=(item)=>{
setAmmount(item)
setCustom(false)
}

    useEffect(() => {
        state?.item?.id && dispatch(RideDetailsById(state.item.id))
        //  dispatch(RideDetailsById())
        dispatch(GetAllCards()).then((res)=>{
            console.log(res,'cards');
            setcardId(res.payload[0].id)
        })
    }, [dispatch, state?.item?.id])
    console.log(RideDetailsUser, 'RideDetailsById');
    console.log(cardId, 'cardId');
    return (
        
        <div>
            <div className="bannerSec spBanner" style={{ backgroundImage: `url('${SpBanner}')` }}>
                <div className="WrapperBnr confirmBooking">
                    <div className="container bnrCon h100 p-0">
                        <div className="cofirmBookingOnBnr">
                            <div className="row cbRow">
                                <div class="odHd">
                                    <h1>Ride Details</h1>
                                    <div class="report">
                                        <span><i class="fas fa-flag"></i></span>
                                        <span>Report</span>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="cbLeft h100">
                                        <div className="cbLcar h100">
                                        {RideDetailsUser?.vehicle? <img src={RideDetailsUser?.vehicle?.image} alt="" />:<Skeleton height={"100%"} width={"100%"} />}    
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="cbRight">
                                        <div className="cbRHd">
                                        {RideDetailsUser?.vehicle? <>
                                        
                                            <span className='orderId'>ORDER # {RideDetailsUser?.ride_code}</span>
                                            <h2>{RideDetailsUser?.vehicle?.name}</h2>
                                            <span className='orderDate'>{moment.utc(RideDetailsUser?.created_at).local().format('MMMM DD , h:mm a')}</span>
                                        </>:
                                        
                                        <Skeleton height={"20%"} width={"60%"} />
                                       
                        
                                        }
                                            
                                        </div>
                                        <ul className='bookingDetail'>
                                            <li className='bdItem'>
                                                <div className="bdLeft">
                                                    <span>Start Trip:</span>
                                                </div>
                                                <div className="bdRight">
                                                    <div className="bdRicon">
                                                        <span className='dot'>
                                                        </span>
                                                    </div>
                                                    <div className="optedItem">
                                                    {RideDetailsUser?.vehicle? <>
                                                        {/* <span>{RideDetailsUser?.ride_log?.ride_start?.slice(7)}</span> */}
                                                        <h3>{RideDetailsUser?.ride_log?.ride_start?.slice(0, 6)}</h3>
                                                        {RideDetailsUser?.ride_log?.start_diff && <p style={{ color: 'red' }} >{RideDetailsUser?.ride_log?.start_diff}</p>}
                                                    </> :<Skeleton height={"20%"} width={"60%"} />}
                                                        
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='bdItem'>
                                                <div className="bdLeft">
                                                    <span>End Trip:</span>
                                                </div>
                                                <div className="bdRight">
                                                    <div className="bdRicon">
                                                        <span className='dot withWhite'>

                                                        </span>
                                                    </div>
                                                    <div className="optedItem">
                                                        {/* <span>{RideDetailsUser?.ride_log?.ride_end?.slice(7)}</span> */}
                                                        <h3>{RideDetailsUser?.ride_log?.ride_end?.slice(0, 6)}</h3>
                                                        {RideDetailsUser?.ride_log?.end_diff && <p style={{ color: 'green' }} >{RideDetailsUser?.ride_log?.end_diff}</p>}
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="totalHrs">
                                            <h3><i class="fas fa-stopwatch"></i> {RideDetailsUser?.duration === 1 ? `${RideDetailsUser?.duration} Hour` : `${RideDetailsUser?.duration} Hours`} </h3>
                                            <p>* A Maximum of 30 minutes of delay time can be expected based on the traffic.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rideDetail">
                <div class="container p-0">
                    <div class="row opRow">
                        <div class="col-lg-7 colOpLt">
                            <div className="colOptions">
                                <div className="rideInfo p-0 infoLine">
                                    <span className='tickOk'><i class="fa fa-check"></i></span> Your Ride Has Completed
                                </div>
                                <div className="ratingReview">
                                <form onSubmit={handleSubmit(onSubmit)} >
                                    <div className="rating">
                                        <h3>How do you like your ride?</h3>
                                        <div className="ratingAlter">
                                            {/* <div class="ratingStars">
                                            <span class="fa fa-star"></span>
                                            <span class="fa fa-star"></span>
                                            <span class="fa fa-star"></span>
                                            <span class="fa fa-star"></span>
                                            <span class="fa fa-star"></span>
                                        </div> */}
                                            <Rating
                                                onChange={(rate) => setRating(rate)}
                                                emptySymbol={<AiOutlineStar size={32} color='#DEDEDE' />}
                                                fullSymbol={<AiFillStar size={32} color='gold' />}
                                                initialRating={rating}
                                            />

                                        </div>
                                        <div className="addReview">
                                            <label for="">Write a Review</label>
                                            <textarea {...register("rev", { required: 'Review is required',minLength: { value: 6, message: ' Review must be atleast 6 characters' },maxLength:{value:100,message:'only 100 charecters allowed'} })} onChange={(e) => setReview(e.target.value)} name="rev" id="" cols="30" >
                                            </textarea>
                                            {errors.rev && <span style={{color:'red'}} >{errors.rev.message}</span>}
                                        </div>

                                      {RideDetailsUser?.driver?.user_stripe &&
                                      <>
                                      <div className="colOptions">
                                      <div className="userCal">
                                          <div className="userLeft">
                                              <img src={RideDetailsUser?.driver?.image} alt="" />
                                          </div>
                                          <div className="userRt">
                                              <span className='usrNm'>Add a tip for {RideDetailsUser?.driver?.full_name}</span>
                                          </div>
                                      </div>
                                  </div>
                                  
                                  <div className="tipPay">
                                                {[30,50,70,100,200].map((item,index)=>(
                                                    <div onClick={()=>handleClick(item)} className={Ammount===item ? "tipActiveItem":"tipItem"}>
                                                    <span>${item}</span>
                                                </div>
                                                ))}
                                                
                                                
                                                <div className="tipItem">
                                                    
                                                {Custom ?
                                                <>
                                                 <input name='name' {...register("name", { pattern:{value:/\d+/ ,message:'Phone must be numbers'} })} className='custom tipItem' control={control} type="number" placeholder='Type amount'  onChange={(e)=>setAmmount(e.target.value)} />
                                                 {errors?.name && <p style={{ color: 'red' }}>{errors?.name.message}</p>}
                                                  </>
                                                  :<span onClick={()=>setCustom(true)} className='custom tipItem' >custom</span>}

                                                </div>
                                            </div>

                                  </>
                                       }  
                                        
                                        <div className="col-lg-6">
                                            
                                            <div className="customAmt">
                                                {/* <label for="">Enter custom amount</label> */}
                                                <div className="cusInpBtn">
                                                    {/* <input type="text" name="ln" ></input> */}
                                                    <button 
                                                    // onClick={()=>handleReviewAndTip(RideDetailsUser?.driver?.user_stripe)}
                                                     >DONE</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
</form>
                                </div>
                               
                            </div>
                        </div>
                        <div class="col-lg-5 colOpRt">
                            <div class="item-deliver">
                                <div class="item-deliver--edit">
                                    <span>Pickup Location:</span>
                                </div>
                                <div class="item-deliver--location">
                                    <div class="item-deliver--location--icon">
                                        {/* <i class="fad fa-map-marker-alt"></i> */}
                                        
                                        <ImLocation color='#F87756' size={20}/>

                                    </div>
                                    <div class="item-deliver--location--locname">
                                        <h2><span>{RideDetailsUser?.location?.title}</span>{RideDetailsUser?.location?.location}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="calcRow bdrTp">
                                <div className="calcPart">
                                    <div class="orderSum">
                                        <h2>Order Summary</h2>
                                        <div class="orders">
                                            <div class="orderin">
                                                <div class="ordItemName"><span>Ride Cost ({RideDetailsUser?.user_payment?.cost_per_hour}/Hour)</span></div>
                                                <div class="itemPrice actualPrice"><span>$ {RideDetailsUser?.user_payment?.ride_cost} </span></div>
                                            </div>
                                            <div class="orderin">
                                                <div class="ordItemName"><span>Service Charges</span></div>
                                                <div class="itemPrice"><span>$ {RideDetailsUser?.user_payment?.service_charge}</span></div>
                                            </div>
                                            {RideDetailsUser?.user_payment?.discount &&
                                                <div class="orderin">
                                                    <div class="ordItemName"><span>Coupon Code Applied</span></div>
                                                    <div class="itemPrice"><span>${RideDetailsUser?.user_payment?.discount}</span></div>
                                                </div>
                                            }

                                            <div class="orderin totalPrice">
                                                <div class="ordItemName"><span>TOTAL</span></div>
                                                <div class="itemPrice"><span>${RideDetailsUser?.user_payment?.total_amount}</span></div>
                                            </div>
                                        </div>
                                        <div className="paidCred">
                                            <div class="paidviaCred">
                                                <div className="pcredwrap">
                                                    <div className="refundMsg">
                                                        <div className='refifpaid'>
                                                            <span><i class="fa fa-check"></i></span> Paid via  card ending {RideDetailsUser?.user_payment?.card_last4}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RideDetail5View;

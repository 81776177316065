import { message, Modal } from 'antd';
import 'bootstrap/dist/css/bootstrap.css';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Logo from '../images/logo.png';
import { Logout } from '../Redux/Features/Authslice';
import LogoBlk from '../images/logo-black.png';
import CartIcon from './CartIcon';
import BellIcon from './BellIcon';
import { GetCartCount } from '../Redux/Features/Shopslice';
import { GetUserNotificationcount } from '../Redux/Features/Userslice';
function NavBar() {
  const navigate = useNavigate()
  const user = localStorage.getItem('revup user')
  const token = Cookies.get('token')
  const {CartCount}=useSelector(state=>state.Shop)
  const [scroll, setScroll] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch()
  



  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    dispatch(Logout()).then((res) => {
      res.type === 'auth/logout/fulfilled' &&
        message.success('Logged out successfully')
      navigate('/')
    })
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, [])
  useEffect(() => {
    dispatch(GetCartCount())
    dispatch(GetUserNotificationcount()).then((res)=>{
      console.log(res,'res in navbar');	
    })
  }, [dispatch,])
  return (
    <div className={"menuNav " + (scroll ? "stickyMenu animated fadeInDown" : "staticMenu")}>
      <Navbar expand="lg" className="navBar">
        <Container>
          <Navbar.Brand href="/" className="revupLogo">
            <img src={Logo} alt="Logo Revup" className='logoWht' />
            <img src={LogoBlk} alt="Logo Revup" className='logoBlk' />

          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto1">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/shop">Shop</Nav.Link>
              {!user || !token ? <>
                <Nav.Link href="#Works">Services</Nav.Link>
                <Nav.Link href="#Works">How it Works</Nav.Link>
                <Nav.Link href="#FAQ">FAQ's</Nav.Link>
              </>
                : ''}

              <Nav.Link href="/contact-us">Contact</Nav.Link>
              {user && token && <Nav.Link href="/cart" className='cartLink'> <span>{<CartIcon />}</span> Cart</Nav.Link> }

              {!user || !token ? <><Nav.Link href="/login" className='loginRup menuReg'>Login</Nav.Link>
                <Nav.Link href="/user-info" className='signRup menuReg'>Sign Up</Nav.Link> </> : ''}

              {
                user && token &&
                <>
                <NavDropdown title={token && user} id="basic-nav-dropdown">
                  <NavDropdown.Item href="/profile-view">Account</NavDropdown.Item>
                  <NavDropdown.Item href="/my-rides">Rides</NavDropdown.Item>
                  <NavDropdown.Item href="/orders">Orders</NavDropdown.Item>
                  {/* <NavDropdown.Item href="/notifications">Notifications</NavDropdown.Item> */}

                  <NavDropdown.Item href="" onClick={showModal}>Logout</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="/notifications">{<BellIcon />}</Nav.Link>
                </>
              }
               
              <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <p>Are you sure to Logout ?</p>

              </Modal>

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    </div>
  );
}

export default NavBar;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from '../../Axios'
import Cookies from 'js-cookie';
export const ChangeUserEmail = createAsyncThunk('user/EmailChange', async (obj, { rejectWithValue }) => {
    // console.log(obj, 'email to api');
    try {
        const res = await axios.put('/user/email/change', {
            email: obj
        },
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const ChangeUserPhone = createAsyncThunk('user/PhoneChange', async (obj, { rejectWithValue }) => {
    // console.log(obj, 'phone to api');
    const { phone, phone_code } = obj
    try {
        const res = await axios.put('/user/phone/change', {
            phone_code: phone_code,
            phone: phone
        },
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data.data
    } catch (error) {
        return rejectWithValue(error.response.data)

    }
})
export const VerifyEmailCode = createAsyncThunk('user/verifyEmailCode', async (obj, { rejectWithValue }) => {
    // console.log(obj, 'phone to api');
    const { sessionId, finalString } = obj
    try {
        const res = await axios.put('user/email/otp/verify', {
            session_id: sessionId,
            otp: finalString
        },
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data
    } catch (error) {
        return rejectWithValue(error.response.data)

    }
})
export const VerifyMobileOtpCode = createAsyncThunk('user/verifyMobileCode', async (obj, { rejectWithValue }) => {
    // console.log(obj, 'phone to api');
    const { sessionId, finalString } = obj
    try {
        const res = await axios.put('/user/phone/otp/verify', {
            session_id: sessionId,
            otp: finalString
        },
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data
    } catch (error) {
        return rejectWithValue(error.response.data)

    }
})
export const UserPhoneVerify = createAsyncThunk('user/userPhoneVerify', async (obj, { rejectWithValue }) => {
    // console.log(obj, 'phone to api');
    const { phone_code, phone } = obj
    try {
        const res = await axios.put('/user/phone/verify', {
            phone_code,
            phone
        },
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data
    } catch (error) {
        return rejectWithValue(error.response.data)

    }
})
export const UpdateUserDetails = createAsyncThunk('user/userDetailUpdate', async (obj, { rejectWithValue }) => {
    // console.log(obj, 'phone to apiiiiiiiiii');
    const { first_name, last_name, image } = obj
    try {
        const res = await axios.put('/user/me', {
            first_name,
            last_name,
            image
        },
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data
    } catch (error) {
        return rejectWithValue(error.response.data)

    }
})
export const GetAllShippingAddress = createAsyncThunk('user/getAllShippingAddress', async (obj, { rejectWithValue }) => {
    // console.log(obj, 'phone to api');

    try {
        const res = await axios.get('/shipping_address?offset=0&limit=10',
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data
    } catch (error) {
        return rejectWithValue(error.response.data)

    }
})
export const GetAllUserNofification = createAsyncThunk('user/getAllUserNotification', async (obj, { rejectWithValue }) => {
    // console.log(obj, 'phone to api');

    try {
        const res = await axios.get('/user_notification?offset=0&limit=10&sort=%5B%5B%22id%22%2C%22desc%22%5D%5D',
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data
    } catch (error) {
        return rejectWithValue(error.response.data)

    }
})
export const ClearUserNofification = createAsyncThunk('user/ClearUserNotification', async (obj, { rejectWithValue }) => {
    // console.log(obj, 'phone to api');

    try {
        const res = await axios.delete('/user_notification',
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data
    } catch (error) {
        return rejectWithValue(error.response.data)

    }
})
export const GetAllState = createAsyncThunk('user/GetAllState', async (obj, { rejectWithValue }) => {
    // console.log(obj, 'phone to api');

    try {
        const res = await axios.get('/state?offset=0&limit=100',
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data
    } catch (error) {
        return rejectWithValue(error.response.data)

    }
})
export const AddNewShippingAddres = createAsyncThunk('user/AddNewShippingAddress', async (obj, { rejectWithValue }) => {
    // console.log(obj, 'ship to api');
    const { address, city, name, state_id, unit, zip_code } = obj

    try {
        const res = await axios.post('/shipping_address',
            {
                name: name,
                address: address,
                unit: unit,
                city: city,
                state_id:parseInt(state_id) ,
                zip: zip_code
            },
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data
    } catch (error) {
        return rejectWithValue(error.response.data)

    }
})
export const DeleteShippingAddres = createAsyncThunk('user/DeleteShippingAddress', async (obj, { rejectWithValue }) => {
    // console.log(obj, 'delete to api');
    

    try {
        const res = await axios.delete(`/shipping_address/${obj}`,
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data
    } catch (error) {
        return rejectWithValue(error.response.data)

    }
})


export const GetUserNotificationcount = createAsyncThunk('user/GetUserNotificationcounts', async (obj, { rejectWithValue }) => {
    // console.log(obj, 'ship to api');
    

    try {
        const res = await axios.get('/user_notification/count',
            
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data
    } catch (error) {
        return rejectWithValue(error.response.data)

    }
})






const initialState = {
    loading: false,
    error: '',
    shippingAddress: {},
    notifications: {},
    states: {},
    UserNoticationCount:{}
}

const Userslice = createSlice({
    name: "second",
    initialState,
    reducers: {},
    extraReducers: {
        [ChangeUserEmail.pending]: (state, { payload }) => {
            state.loading = true
        },
        [ChangeUserEmail.fulfilled]: (state, { payload }) => {
            // console.log('res after email change', payload);
        },
        [ChangeUserEmail.rejected]: (state, { payload }) => {
            state.error = payload
        },
        [GetAllShippingAddress.pending]: (state, { payload }) => {
            state.loading = true
        },
        [GetAllShippingAddress.fulfilled]: (state, { payload }) => {
            // console.log('shipping address', payload.data);
            state.shippingAddress = payload.data
        },
        [GetAllShippingAddress.rejected]: (state, { payload }) => {
            state.error = payload
        },
        [GetAllUserNofification.pending]: (state, { payload }) => {
            state.loading = true
        },
        [GetAllUserNofification.fulfilled]: (state, { payload }) => {
            // console.log('shipping address', payload.data);
            state.notifications = payload.data
        },
        [GetAllUserNofification.rejected]: (state, { payload }) => {
            state.error = payload
        },
        [GetAllState.pending]: (state, { payload }) => {
            state.loading = true
        },
        [GetAllState.fulfilled]: (state, { payload }) => {
            // console.log('shipping address', payload.data);
            state.states = payload.data
        },
        [GetAllState.rejected]: (state, { payload }) => {
            state.error = payload
        },
        [GetUserNotificationcount.pending]: (state, { payload }) => {
            state.loading = true
        },
        [GetUserNotificationcount.fulfilled]: (state, { payload }) => {
            
            state.UserNoticationCount = payload.data
        },
        [GetUserNotificationcount.rejected]: (state, { payload }) => {
            state.error = payload
        },
    }
});

// export const {} = Userslice.actions

export default Userslice.reducer
import React, { useEffect, useState } from 'react';
import BlueCar from '../images/car-blue.png';
import SpBanner from '../images/lamborgini.png'
import SupportPopUp from './SupportPopUp';
import Profile from '../images/prof.jpeg'
import { useLocation } from 'react-router-dom';
import { duration } from 'moment';
import { RideDetailsById } from '../Redux/Features/Dataslice';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton'
import moment from 'moment';
function RideDetail2View() {
    const { state } = useLocation()
    console.log(state, 'is state');
    const [support, setSupport] = useState(false);
    const handleSupportClose = () => setSupport(false);
    const handleSupportShow = () => setSupport(true);
    const dispatch = useDispatch()
    const { RideDetailsUser } = useSelector(State => State.Data)

    useEffect(() => {
        state?.item?.id && dispatch(RideDetailsById(state.item.id))
    }, [dispatch, state?.item?.id])
    console.log(RideDetailsUser, 'is RideDetailsUser');
    return (
        <div>
            <div className="bannerSec spBanner" style={{ backgroundImage: `url('${SpBanner}')` }}>
                <div className="WrapperBnr confirmBooking">
                    <div className="container bnrCon h100 p-0">
                        {!RideDetailsUser?.vehicle ? <div className="cofirmBookingOnBnr">

                            <div className="row cbRow">
                                <div className="col-lg-6">
                                    <div className="cbLeft h100">
                                        <h2>Ride Details</h2>
                                        <div className="cbLcar h100">
                                            <Skeleton height={'100%'} width={'100%'} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="cbRight">
                                        <div className="cbRHd">
                                            <Skeleton height={'60%'} width={'60%'} />
                                        </div>
                                        <ul className='bookingDetail'>
                                            {/* <li className='bdItem'>
                    <div className="bdLeft">
                        <span>Pick Up:</span>
                    </div>
                    <div className="bdRight">
                        <div className="bdRicon">
                            <i className='fad fa-map-marker-alt'></i>
                        </div>
                        <div className="optedItem">
                            <span>Worls Trade Center</span>
                            <h3>285 Fulton St, New York,NY 10007</h3>
                        </div>
                    </div>
                </li> */}
                                            <li className='bdItem'>
                                                <div className="bdLeft">
                                                    <Skeleton height={'60%'} width={'60%'} />
                                                    <Skeleton height={'60%'} width={'60%'} />
                                                </div>
                                                <div className="bdRight">
                                                    <div className="bdRicon">
                                                        <span className='dot'>
                                                        </span>
                                                    </div>
                                                    <div className="optedItem">
                                                        <Skeleton height={'60%'} width={'60%'} />
                                                        <Skeleton height={'60%'} width={'60%'} />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='bdItem'>
                                                <div className="bdLeft">
                                                    <Skeleton height={'60%'} width={'60%'} />
                                                </div>
                                                <div className="bdRight">
                                                    <div className="bdRicon">
                                                        <span className='dot withWhite'>

                                                        </span>
                                                    </div>
                                                    <div className="optedItem">
                                                        <Skeleton height={'100%'} width={'60%'} />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="totalHrs">
                                            <Skeleton height={'100%'} width={'60%'} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                            : <>

                                <div className="cofirmBookingOnBnr">

                                    <div className="row cbRow">
                                        <div className="col-lg-6">
                                            <div className="cbLeft h100">
                                                <h2>Ride Detail</h2>
                                                <div className="cbLcar h100">
                                                    <img src={RideDetailsUser?.vehicle?.image} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="cbRight">
                                                <div className="cbRHd">
                                                    <span>ORDER # {RideDetailsUser?.ride_code}</span>
                                                    <h2>{RideDetailsUser?.vehicle?.name}</h2>
                                                    <sapn>   {moment.utc(RideDetailsUser.created_at).local().format('MMMM DD , h:mm a')} </sapn>
                                                </div>
                                                <ul className='bookingDetail'>
                                                    {/* <li className='bdItem'>
                                            <div className="bdLeft">
                                                <span>Pick Up:</span>
                                            </div>
                                            <div className="bdRight">
                                                <div className="bdRicon">
                                                    <i className='fad fa-map-marker-alt'></i>
                                                </div>
                                                <div className="optedItem">
                                                    <span>Worls Trade Center</span>
                                                    <h3>285 Fulton St, New York,NY 10007</h3>
                                                </div>
                                            </div>
                                        </li> */}
                                                    <li className='bdItem'>
                                                        <div className="bdLeft">
                                                            <span>Start Trip:</span>
                                                        </div>
                                                        <div className="bdRight">
                                                            <div className="bdRicon">
                                                                <span className='dot'>
                                                                </span>
                                                            </div>
                                                            <div className="optedItem">
                                                                <h3>{RideDetailsUser?.ride_start?.slice(0, 6)}</h3>
                                                                
                                                                <span>{RideDetailsUser?.ride_start?.slice(7)}</span>


                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className='bdItem'>
                                                        <div className="bdLeft">
                                                            <span>End Trip:</span>
                                                        </div>
                                                        <div className="bdRight">
                                                            <div className="bdRicon">
                                                                <span className='dot withWhite'>

                                                                </span>
                                                            </div>
                                                            <div className="optedItem">
                                                                <h3>{RideDetailsUser?.ride_end?.slice(0, 6)}</h3>
                                                                <span>{RideDetailsUser?.ride_end?.slice(7)}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <div className="totalHrs">
                                                    <h3><i class="fas fa-stopwatch"></i> {RideDetailsUser?.duration === 1 ? `${RideDetailsUser?.duration} Hour` : `${RideDetailsUser?.duration} Hours`}</h3>
                                                    <p>* A Maximum of 30 minutes of delay time can be expected based on the traffic.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </>}

                    </div>
                </div>
            </div>
            <div className="rideDetail">
                <div class="container p-0">
                    <div class="row opRow">
                        <div class="col-lg-6 colOpLt">
                            <div class="item-deliver">
                                <div class="item-deliver--edit"><span>Pickup Location:</span></div>
                                <div class="item-deliver--location">
                                    <div class="item-deliver--location--icon"><i class="fad fa-map-marker-alt"></i></div>
                                    <div class="item-deliver--location--locname">
                                        <h2><span>{RideDetailsUser?.location?.title}</span>{RideDetailsUser?.location?.location}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 colOpRt withDetail">
                            <div className="colOptions reshedule unique-shedule">
                                {state?.item?.driver &&
                                    <>
                                        <div className="userCal">
                                            <div className="userLeft">
                                                <img src={RideDetailsUser?.driver?.image ? RideDetailsUser?.driver?.image : `https://joeschmoe.io/api/v1/${RideDetailsUser?.driver?.full_name}`} alt="" />
                                            </div>
                                            <div className="userRt">
                                                <span className='usrNm'>{RideDetailsUser?.driver?.full_name}</span>
                                                <span className='usrInfo'>{RideDetailsUser?.driver?.message}</span>
                                            </div>
                                        </div>
                                        <div className="connect">
                                            <div className="call connWith">

                                                <a href={`tel:${RideDetailsUser?.driver?.phone_numbe}`}><i className='fa fa-phone'  ></i></a>
                                            </div>

                                        </div>
                                    </>
                                }
                            </div>

                            <div className="cancelRide">

                                <button disabled={RideDetailsUser.ride_status_id=='8' } className='cancelBtn' onClick={handleSupportShow}>{RideDetailsUser.ride_status_id=='8' ?'Reshedule Requested':'Request To Reshedule'} </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                support && <SupportPopUp Ride_id={RideDetailsUser?.id} state={state} handleSupportClose={handleSupportClose} support={support} />
            }
        </div>
    );
}

export default RideDetail2View;

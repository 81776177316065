import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from '../../Axios'
import Cookies from 'js-cookie';
export const GetAllProducts = createAsyncThunk('product/getAll', async (obj, { rejectWithValue }) => {
    console.log(obj, 'items to api');
   
    
    try {
        if(obj.price==='high'){
            const res = await axios.get(`/product?offset=${obj.data ? obj.data*8 : 0*8}&limit=8&populate=%5B%22product_image%22%5D&sort=[["unit_price","desc"]] `, {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
            return res.data.data
            
        }
        if(obj.price==='low'){
            const res = await axios.get(`/product?offset=${obj.data ? obj.data*8 : 0*8}&limit=8&populate=%5B%22product_image%22%5D&sort=[["unit_price","asc"]] `, {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
            return res.data.data
            
        }
        if(obj.price==='new'){
            const res = await axios.get(`/product?offset=${obj.data ? obj.data*8 : 0*8}&limit=8&populate=%5B%22product_image%22%5D&sort=[["created_at","desc"  ]] `, {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
            return res.data.data
            
        }
        if(obj.price==='old'){
            const res = await axios.get(`/product?offset=${obj.data ? obj.data*8 : 0*8}&limit=8&populate=%5B%22product_image%22%5D&sort=[["created_at","asc"]] `, {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
            return res.data.data
            
        }
        
            const res = await axios.get(`/product?offset=${obj ? obj*8 : 0*8}&limit=8&populate=%5B%22product_image%22%5D&sort=%5B%5B%22unit_price%20%22%2C%22desc%22%5D%5D`, {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
            return res.data.data
        



        
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const GetSingleProduct = createAsyncThunk('product/getsingle', async (obj, { rejectWithValue }) => {
    console.log(obj, 'product to api');
    try {
        const res = await axios.get(`/product/${obj}?populate=%5B%22product_image%22%2C%22review%22%2C%20%22review.user%22%5D`, {
            headers: { Authorization: `Bearer ${Cookies.get('token')}` }
        })
        return res.data.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const AddtoCart = createAsyncThunk('product/AddtoCart', async (obj, { rejectWithValue }) => {
    console.log(obj, 'product to Cart');
    try {
        const res = await axios.post(`/cart`, {
            product_id: obj,
            quantity: 1
        },
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const GetCart = createAsyncThunk('product/GetCart', async (obj, { rejectWithValue }) => {
    console.log(obj, 'product to Cart');
    try {
        const res = await axios.get(`/cart?offset=0&limit=10`,
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const GetCartCount = createAsyncThunk('product/GetCartCount', async (obj, { rejectWithValue }) => {
    console.log(obj, 'product to Cart');
    try {
        const res = await axios.get(`/cart/count`,
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const RemoveCartById = createAsyncThunk('product/RemoveCartById', async (obj, { rejectWithValue }) => {
    console.log(obj, 'delete Cart id');
    try {
        const res = await axios.delete(`/cart/${obj}`,
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const UpdateCartById = createAsyncThunk('product/UpdateCartById', async (obj, { rejectWithValue }) => {
    console.log(obj, 'update Cart id');
    const { count, id,active,cartId } = obj
    try {
        const res = await axios.put(`/cart/${cartId}`,
            {
                active:true,
                product_id: id,
                quantity: count
            },
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const ConfirmOrder = createAsyncThunk('product/ConfirmOrder', async (obj, { rejectWithValue }) => {
    console.log(obj, 'update Cart id gffdgfdgdf');
    
    try {
        const res = await axios.post(`/product/confirm_order`,
            {
               
                "product": 
                    obj?.length >0 && obj.map((item)=>{
                        return(
                            {
                                "id": item.product_id,
                                "qty": item.quantity
                              }
                        )
                    })
                    
                  
                    
            },
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})



const initialState = {
    loading: false,
    Products: {},
    SingleProduct: {},
    CartItems: {},
    CartCount: {},
    error: {},
    orderPrice:{}
}

const Shopslice = createSlice({
    name: "second",
    initialState,
    reducers: {},
    extraReducers: {
        [GetAllProducts.pending]: (state, action) => {
            state.loading = true
        },
        [GetAllProducts.fulfilled]: (state, action) => {
            state.loading = false
            state.Products = action.payload
        },
        [GetAllProducts.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
        [GetSingleProduct.pending]: (state, action) => {
            state.loading = true
            state.SingleProduct = {}
        },
        [GetSingleProduct.fulfilled]: (state, action) => {
            state.loading = false
            state.SingleProduct = action.payload
        },
        [GetSingleProduct.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
        [GetCart.pending]: (state, action) => {
            state.loading = true
        },
        [GetCart.fulfilled]: (state, action) => {
            state.loading = false
            state.CartItems = action.payload
        },
        [GetCart.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
        [GetCartCount.pending]: (state, action) => {
            state.loading = true
        },
        [GetCartCount.fulfilled]: (state, action) => {
            state.loading = false
            state.CartCount = action.payload
        },
        [GetCartCount.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
        [ConfirmOrder.pending]: (state, action) => {
            state.loading = true
        },
        [ConfirmOrder.fulfilled]: (state, action) => {
            state.loading = false
            console.log(action.payload.data.rate,'action.payload');
            state.orderPrice = action.payload.data.rate
        },
        [ConfirmOrder.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
    }
});



export default Shopslice.reducer
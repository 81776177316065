import React,{useEffect, useState} from 'react';
import BlueCar from '../images/car-blue.png';
import SpBanner from '../images/lamborgini.png'
import SupportPopUp from './SupportPopUp';
import Profile from '../images/prof.jpeg'
import {useJsApiLoader,GoogleMap,Marker,Autocomplete,DirectionsRenderer} from '@react-google-maps/api'
import { useLocation } from 'react-router-dom';
import { RideDetailsById } from '../Redux/Features/Dataslice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
const libraries = ['places'];
const dest={lat:8.4628684,lng:76.9733717}



function RideDetail3View() {
    const { RideDetailsUser } = useSelector(State => State.Data)
  const [support,setSupport] = useState(false);
  const [Direction,setDirection] = useState(null);
  const [distance,setdistance] = useState('');
  const [duration,setduration] = useState('');
  const handleSupportClose = () => setSupport(false);
  const dispatch =useDispatch()
  const handleSupportShow = () => setSupport(true);
  
  
  const{state}=useLocation()
  const center={lat:parseFloat(RideDetailsUser?.latitude),lng:parseFloat(RideDetailsUser?.longitude)}
  const desti={lat:parseFloat(RideDetailsUser?.location?.latitude),lng:parseFloat(RideDetailsUser?.location?.longitude)}
  const {isLoaded}=useJsApiLoader({
    googleMapsApiKey:'AIzaSyDIj0QBVIMnAxlKJf4WbGcISWXPvchSj-Y',
    libraries
})
useEffect(() => {
    console.log(desti,'desti');
    console.log(center,'center');
    console.log('origin lat lng',RideDetailsUser?.location?.latitude,RideDetailsUser?.location?.longitude);
    console.log('destination lat lng',RideDetailsUser?.latitude,RideDetailsUser?.longitude);
    state?.item?.id && dispatch(RideDetailsById(state.item.id))
    const calculateRoute =async()=>{
        const DirectionsService = new window.google.maps.DirectionsService();
        console.log(parseFloat(RideDetailsUser?.location?.latitude,'sickk'));
       const res=await DirectionsService.route({
           // eslint-disable-next-line no-undef
           origin:new google.maps.LatLng(40.705608,-74.016724),
            // eslint-disable-next-line no-undef
            // destination: new google.maps.LatLng(desti.lat,desti.lng),
        // eslint-disable-next-line no-undef
            destination: new google.maps.LatLng(8.4628684, 76.9733717  ),
            // eslint-disable-next-line no-undef
            travelMode:google.maps.TravelMode.DRIVING,
        })
        setDirection(res)
        console.log(res,'dgdddddddddddddddddddddddddddddddddddd');
        setdistance(res?.routes[0].legs[0].distance.text)
        setduration(res?.routes[0].legs[0].duration.text)
    }
    calculateRoute()
}, [dispatch,state.item.id])
console.log(RideDetailsUser,'RideDetailsUser are');
if (!isLoaded) {
    return <h1>Loading ....</h1>
}

  return (
    <div>
        <div className="bannerSec spBanner" style={{ backgroundImage: `url('${SpBanner}')` }}>
            <div className="WrapperBnr confirmBooking">
                <div className="container bnrCon h100 p-0">
                    <div className="cofirmBookingOnBnr">
                        <div className="row cbRow">
                            <div className="col-lg-6">
                                <div className="cbLeft h100">
                                    <h2>Ride Details</h2>
                                    <div className="cbLcar h100">
                                        <img src={RideDetailsUser?.vehicle?.image} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="cbRight">
                                    <div className="cbRHd">
                                        <span>ORDER # {RideDetailsUser?.ride_code}</span>
                                        <h2>{RideDetailsUser?.vehicle?.name}</h2>
                                        <sapn>   {moment.utc(RideDetailsUser.created_at).local().format('MMMM DD , h:mm a')} </sapn>
                                    </div>
                                    <ul className='bookingDetail'>
                                        {/* <li className='bdItem'>
                                            <div className="bdLeft">
                                                <span>Pick Up:</span>
                                            </div>
                                            <div className="bdRight">
                                                <div className="bdRicon">
                                                    <i className='fad fa-map-marker-alt'></i>
                                                </div>
                                                <div className="optedItem">
                                                    <span>{RideDetailsUser?.location?.title}</span>
                                                    <h3>{RideDetailsUser?.location?.location}</h3>
                                                </div>
                                            </div>
                                        </li> */}
                                        <li className='bdItem'>
                                            <div className="bdLeft">
                                                <span>Start Trip:</span>
                                            </div>
                                            <div className="bdRight">
                                                <div className="bdRicon">
                                                    <span className='dot'>
                                                    </span>
                                                </div>
                                                <div className="optedItem">
                                                    <h3>{RideDetailsUser?.ride_start?.slice(0, 6)}</h3>
                                                    <span>{RideDetailsUser?.ride_start?.slice(7)}</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li className='bdItem'>
                                            <div className="bdLeft">
                                                <span>End Trip:</span>
                                            </div>
                                            <div className="bdRight">
                                                <div className="bdRicon">
                                                    <span className='dot withWhite'>

                                                    </span>
                                                </div>
                                                <div className="optedItem">
                                                    <h3>{RideDetailsUser?.ride_end?.slice(0, 6)}</h3>
                                                    <span>{RideDetailsUser?.ride_end?.slice(7)}</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="totalHrs">
                                        <h3><i class="fas fa-stopwatch"></i> {RideDetailsUser?.duration === 1 ? `${RideDetailsUser?.duration} Hour` : `${RideDetailsUser?.duration} Hours`}</h3>
                                        <p>* A Maximum of 30 minutes of delay time can be expected based on the traffic.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="rideDetail">
            <div class="container p-0">
                <div class="row opRow">
                    <div class="col-lg-6 colOpLt">
                        <div class="item-deliver">
                            <div class="item-deliver--edit"><span>Pickup Location:</span></div>
                            <div class="item-deliver--location">
                            <div class="item-deliver--location--icon"><i class="fad fa-map-marker-alt"></i></div>
                            <div class="item-deliver--location--locname">
                                <h2><span>{RideDetailsUser?.location?.title}</span>{RideDetailsUser?.location?.location}</h2>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 colOpRt withDetail">
                        <div className="colOptions">
                            <div className="userCal">
                                <div className="userLeft">
                                    <img src={RideDetailsUser?.driver?.image} alt="" />
                                </div>
                                <div className="userRt">
                                    <span className='usrNm'>{RideDetailsUser?.driver?.full_name}</span>
                                    <span className='usrInfo'> {RideDetailsUser?.driver?.message}</span>
                                </div>
                            </div>
                            <div className="connect">
                                <div className="arrival">
                                    <span>Arrives in {duration} min</span>
                                </div>
                                <div className="call connWith">
                                <a href={`tel:${RideDetailsUser?.driver?.phone_numbe}`}><i   className='fa fa-phone'  ></i></a>
                                    
                                </div>
                                {/* <div className="msg connWith">
                                    <i className='fa fa-comment'></i>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="locationRow">
                        <div className="google-map-code" style={{width:'100%',height:'40vh'}}>
                        <GoogleMap 
           center={center}
            zoom={10} 
            mapContainerStyle={{width:'100%',height:'100%'}}
            options={{
                zoomControl:false,
                streetViewControl:false,
                fullscreenControl:false,
                mapTypeControl:false,
            }}
             >
                 <Marker 
                 title='Pickup Location'
                 position={center}
                  />
                 <Marker
                 title='Drop Location'
                  position={desti} />
                 {/* {direction&& <DirectionsRenderer directions={direction}/>} */}
                 {Direction&& <DirectionsRenderer directions={Direction}/>}

           </GoogleMap>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
        {
            support && <SupportPopUp handleSupportClose={handleSupportClose} support={support} />
        }
    </div>    
  );
}

export default RideDetail3View;

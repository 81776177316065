import React from 'react';
import profile from '../images/prof.jpeg'
import moment from 'moment'
import { useSelector } from 'react-redux';
function CustomerReview({singleCar}) {
    let review ;
    // const { SingleProduct,loading } = useSelector(state => state.Shop)
    console.log('triggered', singleCar);
    // console.log(SingleProduct,'dfdfsdgbdf');
    var dateAndTime = moment().format("YYYY/MM/DD HH:mm:ss")
    
    return (
        <div>
            <div className="cusReview">
                <div className="container">
                    <h2>Customer Reviews</h2>
                    <div className="row crRow">
                
                        
                        {
                            
                            singleCar?.length > 0 ? singleCar?.map((i) => (
                                <div className="col-lg-6 colReview">
                                    <div className="reviewHd">
                                        <div className="rhdThumb">
                                            <img src={profile} alt="" />
                                        </div>
                                        <div className="rhdReview">
                                            <h2>{i?.user?.full_name} <span>{moment(i.created_at).fromNow()}</span></h2>
                                            <div className="reviewStar">
                                                {
                                                    [...Array(i.rating)].map((i) => (
                                                        <i class="fa fa-star"></i>
                                                    ))
                                                }

                                                {/* <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cusComment">
                                        <p>{i.review}</p>
                                    </div>
                                </div>

                            )) : <h2>No Reviews</h2>
                        }
                       

                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomerReview;

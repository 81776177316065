import { Skeleton } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetMyRides } from '../Redux/Features/Dataslice';
import moment from 'moment';
import Profile from '../images/prof.jpeg'
import { useNavigate } from 'react-router-dom';
import Rating from 'react-rating';
import { AiOutlineStar, AiFillStar } from "react-icons/ai";

function MyRidesView() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { myRides, loading } = useSelector(State => State.Data)
    const date = '2022-03-15T19:07:33.000Z'
    console.log(date);
    const converted = moment.utc(date).local().format('MMMM Do , h:mm:ss a')
    console.log('converted', converted);
    useEffect(() => {
        dispatch(GetMyRides())
    }, [dispatch])

    return (
        <div className='myRidesView'>
            <div className="container">
                <div className="ridesWrapper">
                    <div className="ridesHead">
                        <h1>My Rides</h1>

                    </div>
                    <div className="ridesList table-responsive">
                        {/*  */}
                        <table class="table table-image">
                            <tbody>
                                {/* list 1 */}

                                {
                                    loading && [...Array(4)].map((_, i) => (
                                        <div key={i}>
                                            <Skeleton avatar active loading />
                                        </div>
                                    ))
                                }
                                {!loading && myRides.length > 0 ? myRides.map((i) => (
                                    <tr className={i.ride_status_id === 3 ? 'cancelledRow' : ''} style={{ cursor: 'pointer' }} onClick={() => {


                                        if (i.driver_assigned_on === null) {
                                            navigate('/ride-detail', { state: { item: i } })
                                        }
                                        if (i.driver.message === "Your ride completed & not rated yet") {
                                            navigate('/ride-detail5', { state: { item: i } })
                                        }
                                        if (i.driver.message === "Your ride completed") {
                                            navigate('/ride-detail4', { state: { item: i } })
                                        }
                                        if (i.driver.message === "Driver Info") {
                                            navigate('/ride-detail2', { state: { item: i } })
                                        }
                                        if (!i.driver.message) {
                                            navigate('/ride-detail', { state: { item: i } })
                                        }
                                        if (i.ride_status_id === 5) {
                                            navigate('/ride-detail3', { state: { item: i } })
                                        }
                                        if (i.ride_status_id === 4) {
                                            navigate('/ride-detail3', { state: { item: i } })
                                        }
                                        if (i.ride_status_id === 6) {
                                            navigate('/ride-detail3', { state: { item: i } })
                                        }
                                        if (i.ride_status_id === 8) {
                                            navigate('/ride-detail2', { state: { item: i } })
                                        }





                                    }} >
                                        <td class="w-25">
                                            <div className="ride-Item">
                                                <div className="product--img">
                                                    <img src={i.vehicle_image} alt="" />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="product--name">
                                                <div className="ride--car">
                                                    <span className='orderId'>ORDER # {i.ride_code}</span>
                                                    <span className='orderName'>{i.vehicle_name}</span>
                                                </div>
                                                <div className="ride--date">
                                                    <span>{moment.utc(i.created_at).local().format('MMMM DD , h:mm a')}</span>
                                                    {i.ride_status_id === 3 && <span style={{ color: 'red' }}>Your ride has cancelled on {moment.utc(i.updated_at).local().format('MMMM DD , h:mm a')}</span>}
                                                </div>
                                                {i.driver &&

                                                    <div className="ride--info">
                                                        <div className="ride--info--thumb">
                                                            {i.ride_status_id === 3 ? <></> :
                                                                

                                                                    i.ride_status_id !== 7 &&
                                                                    <div className="ride--info--thumb__img">
                                                                    
                                                                    <img src={i.driver.image ? i.driver.image : `https://joeschmoe.io/api/v1/${i.driver.full_name}`} alt="Profile" />
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="ride--info--detail">

                                                            {i.ride_status_id === 3  ? <></> :

                                                                <>
                                                                   {i.ride_status_id !== 7 && <h3>{i.driver.full_name}</h3>} 
                                                                    <span style={{
                                                                        color: i.ride_status_id === 7 ? "green" : "",
                                                                        
                                                                    }}   >{i.driver.message}</span>
                                                                </>}


                                                            {i.driver.message === "Your ride completed & not rated yet" && <span><Rating
                                                                onChange={(rate) => console.log(rate)}
                                                                emptySymbol={<AiOutlineStar size={24} color='#DEDEDE' />}
                                                                fullSymbol={<AiOutlineStar size={24} color='gold' />}
                                                            /></span>}

                                                            {i.driver.message === "Your ride completed" && <span>
                                                                <Rating
                                                                    initialRating={i.review.rating}
                                                                    emptySymbol={<AiOutlineStar size={24} color='#DEDEDE' />}
                                                                    fullSymbol={<AiFillStar size={24} color='gold' />}
                                                                    readonly
                                                                /> </span>}

                                                        </div>
                                                    </div>

                                                }



                                            </div>
                                        </td>
                                        <td>
                                            <div className="Trip">
                                                <div className="bdRight">
                                                    <div class="bdRicon">
                                                        <span class="dot"></span>
                                                    </div>
                                                    <div className="optedItem">
                                                        <label>Start Trip:</label>
                                                        <h3>{i.ride_start.slice(0, 6)}</h3>
                                                        <span>{i.ride_start.slice(7)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="Trip">
                                                <div className="bdRight">
                                                    <div class="bdRicon">
                                                        <span class="dot withWhite"></span>
                                                    </div>
                                                    <div className="optedItem">
                                                        <label>End Trip:</label>
                                                        <h3>{i.ride_end.slice(0, 6)}</h3>
                                                        <span>{i.ride_end.slice(7)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="usedHrs">
                                                <span>
                                                    <i class="fas fa-stopwatch"></i> {i.duration == '1' ? `${i.duration}Hour` : `${i.duration}Hours`}
                                                </span>
                                            </div>

                                        </td>
                                    </tr>
                                )) : !loading && <h1>No Rides Booked</h1>




                                }



                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyRidesView;




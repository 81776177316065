import { message } from 'antd';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SpBanner from '../images/lamborgini.png';
import regBg from '../images/regImg.png';
import { ResendOtp, Signup, VerifyOTP } from '../Redux/Features/Authslice';
function VerifyAccountView() {
    const [OTP, setOTP] = useState(new Array(6).fill(""));
    const { sessionId, error } = useSelector(state => state.Auth)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleChange = (element, index) => {
       
        if (isNaN(element.value)) return false;
        setOTP([...OTP.map((d, idx) => (idx === index) ? element.value : d)]);
        
        
    }
    const inputfocus = (elmnt) => {
        
        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
          const next = elmnt.target.tabIndex - 2;
          if (next > -1) {    
            elmnt.target.form.elements[next].focus()
          }
        }
        else {
          console.log("next");         
            const next = elmnt.target.tabIndex;
            if (next < 6 && !isNaN(elmnt.key)) {
                
              elmnt.target.form.elements[next].focus()
            }
        }
    
      }
    let finalString = OTP.join("");
    console.log(finalString);
    console.log(typeof finalString);
    const handleVerifyOtp = () => {
        dispatch(VerifyOTP({ sessionId, finalString })).then((res) => {
              console.log(res);
            if (res?.payload?.data?.session_id) {
                dispatch(Signup(res.payload.data.session_id)).then((resp) => {
                    
                    Cookies.set('token', resp.payload.data.token)
                    localStorage.setItem('revup user', resp.payload.data.user.full_name)

                    sessionStorage.removeItem('first_name')
                    sessionStorage.removeItem('last_name')
                    sessionStorage.removeItem('email')
                    sessionStorage.removeItem('phone_code')
                    sessionStorage.removeItem('phone')
                    sessionStorage.removeItem('password')


                    message.success('Successfully Signed in')
                    resp && navigate('/')
                })

            } else {

            }

        })
    }
    return (
        <div>
            <div className="bannerSec spBanner" style={{ backgroundImage: `url('${SpBanner}')` }}>
                <div className="WrapperBnr">

                </div>
            </div>
            <div className="accRegistration">
                <div className="container">
                    <div className="row accRow">
                        <div className="col-lg-6 colRgLeft p-0">
                            <div className="rgLeftWrap">
                                <div className="rgLcon h100">
                                    <div className="rgLHd">
                                        <h2><span>Enjoy the </span> <span>Experience with us!</span></h2>
                                    </div>
                                    <div className="rgLimg">
                                        <img src={regBg} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 colRgRight p-0 align-self-center">
                            <div className="rgRtWrap">
                                <div className="rgRtHd">
                                    <h2>Verify Account</h2>
                                    <span>We send a six digit verification code to {sessionStorage.getItem('email')}</span>
                                    {error && <h3>Invalid Code</h3>}
                                </div>
                                <div action="#" class="register-form">
                                    <form className="formWrap formWrap--item">
                                        {
                                            OTP.map((data, i) => {
                                                return (
                                                    <>
                                                        <input
                                                            className='otp-field'
                                                            type="text"
                                                            name="otp"
                                                            maxLength="1"
                                                            key={i}
                                                            value={data}
                                                            onChange={e => handleChange(e.target, i)}
                                                            onFocus={e => e.target.select()}
                                                            onKeyUp={e => inputfocus(e)}
                                                            tabIndex={i+1}
                                                        />
                                                    </>
                                                );
                                            })
                                        }
                                    </form>
                                    <button onClick={handleVerifyOtp} type="submit" class="btn">Next</button>

                                    <div className="noCode">
                                    <p class="fp">Don't receive code? <span className='ss' onClick={()=>dispatch(ResendOtp(sessionId)).then((resp)=> resp.type=== "auth/Resendotp/fulfilled"? message.success('verification code sent successfully' ) : message.warn('failed to sent verification code') )} >Resend</span></p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VerifyAccountView;

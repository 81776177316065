import React, { useState } from 'react'
import NavBar from '../Componants/NavBar';
import SpBanner from '../images/lamborgini.png';
import Footer from '../Componants/Footer';
import LeftArrow from '../images/left-arrow.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ChangeUserEmail } from '../Redux/Features/Userslice';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { useForm } from 'react-hook-form';
function ChangeEmail() {
    const { state } = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { register, handleSubmit, control, formState: { errors } } = useForm()
    const [email, setEmail] = useState(state.email)
    //  function for changing email
    const handleEmailChange = () => {

    }
    const onSubmit = (data) => {
        console.log(data, 'phone change');
        dispatch(ChangeUserEmail(data.email)).then((res) => {
            console.log(res, 'resssss');
            if (res.type === 'user/EmailChange/fulfilled') {
                message.info('verification Code Sent Successfully')
                navigate('/verify-email', { state: { data: res?.payload?.session_id, email: data?.email } })
            } else {
                message.info(res?.payload?.message)
            }


            // console.log('res after email change',res?.payload?.data);
            // res.payload?.data?.message === 'OTP sent' &&

        })

    }

    return (
        <div>
            <NavBar />
            <div className="bannerSec spBanner" style={{ backgroundImage: `url('${SpBanner}')` }}>
                <div className="WrapperBnr">
                </div>
            </div>
            <div className="editProfile pb60">
                <div className="container">
                    <div className="row epRow">
                        <div className="epIn col-lg-4">
                            <div className="epHd ep-change">
                                <h2 className='epHd'>
                                    <span>
                                        <Link to="/profile-view" > <img src={LeftArrow} alt="" /> </Link>
                                    </span>
                                    Change Email
                                </h2>
                                <span className='ep-change--sub'>When you change your email, you'll receive a verification code to verify your account</span>
                            </div>
                            <div className="product--name epDetail">
                                <div className="epForm register-form">
                                    <form onSubmit={handleSubmit(onSubmit)} >
                                        <div className="form-controller">
                                            <label htmlFor="">Email</label>
                                            <input {...register('email', { required: 'E-mail is required',
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "invalid email address"
                                        }
                                         })} type="email" name="email" defaultValue={state.email} />
                                            {errors?.email && <p style={{ color: 'red' }}>{errors?.email.message}</p>}
                                        </div>
                                        <button  type="submit" class="btn btn-save">CONTINUE</button>
                                    </form>
                                    {/* <Link to="/verify-email"> */}
                                    

                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer noTopSpace />
        </div>
    );
}

export default ChangeEmail;
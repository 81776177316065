import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Footer from '../Componants/Footer';
import NavBar from '../Componants/NavBar';
import SpBanner from '../images/lamborgini.png';
import Cookies from 'js-cookie';
import { Parser } from 'html-to-react'
function Terms() {
  const [Terms,setTerms] = useState();
  useEffect(() => {
    axios.get('https://staging-api.revupnyc.com/page/terms/web_views',{
      headers: { Authorization: `Bearer ${Cookies.get('token')}` }
    }).then((res)=>{
        console.log(res.data);
        setTerms(res.data)
    })
  }, [])
  const rawHTML = Terms
  return (
    <>
        <NavBar />
        <div className="bannerSec spBanner" style={{ backgroundImage: `url('${SpBanner}')` }}>
            <div className="WrapperBnr">                
            </div>
        </div>
       <div className="container">
         {/* {Terms} */}
         
         {/* <div dangerouslySetInnerHTML={{ __html: Terms }} ></div> */}
         {/* <div > {Parser().parse(rawHTML)}</div> */}
         
            <div className="termWrap">
                <h1>Terms</h1>
                <div className="termsDec">
                    <h2>Lorem ipsum dolorconset. </h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. At ultrices mi tempus imperdiet nulla. Porttitor lacus luctus accumsan tortor posuere ac ut consequat. Enim diam vulputate ut pharetra sit amet aliquam id. Pulvinar neque laoreet suspendisse interdum consectetur. Justo donec enim diam vulputate ut pharetra. Nisl suscipit adipiscing bibendum est. Adipiscing elit ut aliquam purus sit amet luctus venenatis lectus. Turpis cursus in hac habitasse platea dictumst quisque. Massa vitae tortor condimentum lacinia quis vel eros donec ac. Felis donec et odio pellentesque diam volutpat commodo. Morbi quis commodo odio aenean sed. Nec ullamcorper sit amet risus. Lacus viverra vitae congue eu consequat. Donec ac odio tempor orci dapibus ultrices. Convallis a cras semper auctor neque vitae tempus quam. Ac auctor augue mauris augue neque gravida. Praesent semper feugiat nibh sed pulvinar proin gravida hendrerit lectus. Lectus vestibulum mattis ullamcorper velit sed ullamcorper morbi..</p>
                </div>
            </div>
       </div>
       <Footer noTopSpace/>
    </>
  );
}

export default Terms;
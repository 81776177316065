import { message } from 'antd';
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AddNewShippingAddres, GetAllShippingAddress, GetAllState } from '../Redux/Features/Userslice';

function AddNewAddress({setAddAdress}) {

    const { register, handleSubmit, control, formState: { errors } } = useForm()
    const { states } = useSelector(state => state.User)
    const dispatch = useDispatch()
   
    const onSubmit = (data) => {

        // console.log(data, 'sD');
        dispatch(AddNewShippingAddres(data)).then((res)=>{
            console.log(res, 'res after adding new ship');	
            if (res?.payload?.message=== "Created") {
                message.info('Address added successfully')
                setAddAdress(false)
                dispatch(GetAllShippingAddress())
            }else{
                message.warning('Address Verification failed')
            }
            
        })



    }

    useEffect(() => {
        dispatch(GetAllState()).then((res) => {
            console.log(res, 'res after state');
        })
    }, [dispatch])
    console.log(states, 'states');
    return (
        <div>
            <div class="rgRtWrap">
                <div class="rgRtHd addNwAdrsHd">
                    <h2>Add Shipping Address</h2>
                </div>
                <div action="#" class="register-form addNewForm">
                    <form onSubmit={handleSubmit(onSubmit)} >
                        <div class="formWrap">
                            <div class="form-controller">
                                <label for="">Name</label>

                                <input {...register('name', { required: ' Name is required', pattern: [/[a-z]/, /[A-Z]/],  minLength: { value: 3, message: 'Please provide minimum 3 characters' }, pattern: { value: /^[A-Za-z]+$/i, message: 'Name Must be Alphabets' } })} type="text" autocomplete="off" name="name" placeholder=" Name" />
                                {errors?.name && <p style={{ color: 'red' }}>{errors?.name.message}</p>}
                            </div>
                            <div class="form-controller">
                                <label for="">Address</label>
                                <input {...register('address', { required: ' Address is required', pattern: [/[a-z]/, /[A-Z]/, /[0-9]/],  minLength: { value: 3, message: 'Please provide minimum 3 characters' },  })} type="text" autocomplete="off" name="address" placeholder=" Address    " />
                                {errors?.address && <p style={{ color: 'red' }}>{errors?.address.message}</p>}
                            </div>
                            <div class="form-controller">
                                <label for="">Unit/Floor</label>
                                <input {...register('unit', { required: ' Unit/floor is required', pattern: [/[a-z]/, /[A-Z]/, /[0-9]/],  minLength: { value: 3, message: 'Please provide minimum 3 characters' }, })} type="text" autocomplete="off" name="unit" placeholder=" Unit/Floor" />
                                {errors?.unit && <p style={{ color: 'red' }}>{errors?.unit.message}</p>}
                            </div>
                            <div class="form-controller">
                                <label for="">City</label>
                                <input {...register('city', { required: ' City is required', pattern: [/[a-z]/, /[A-Z]/], minLength: { value: 3, message: 'Please provide minimum 3 characters' }, })} type="text" autocomplete="off" name="city" placeholder=" City" />
                                {errors?.city && <p style={{ color: 'red' }}>{errors?.city.message}</p>}
                            </div>
                            <div class="form-controller">
                                <label for="">State</label>


                                {/* use form start */}

                                <select className='select-state' {...register("state_id", { required: 'select one State' })} name='state_id'>
                                    {states?.states?.map((item) => (
                                        <>
                                        <option selected disabled hidden>Select</option>
                                        <option value={item.id}>{item.name}</option>
                                        </>
                                    ))}
                                </select>
                                {errors?.state_id && <p style={{ color: 'red' }}>{errors?.state_id.message}</p>}

                                {/* end */}



                                {/* <input {...register('state', { required:' Name is required',pattern: /^[A-Za-z]+$/i,minLength:10,minLength:{value:3,message:'Please provide minimum 3 characters'} , maxLength:{value:15,message:'Please provide maximum 15 characters'},pattern:{value:/^[A-Za-z]+$/i,message:'Name Must be letters'} })} type="text" autocomplete="off" name="state" placeholder="State" />  
              {errors?.unit && <p style={{ color: 'red' }}>{errors?.unit .message}</p>} */}
                            </div>
                            <div class="form-controller">
                                <label for="">Zip Code</label>
                                <input {...register('zip_code', { required: 'Zip Code is required', pattern: { value: /\d+/, message: 'Zip Code must be numbers' }, minLength: { value: 5, message: ' Zip Code  must be 5 digits ' },maxLength:{value:7,message:'Zip code should be less than 7 digits'} })} className='phnin' type="number" autocomplete="off" name="zip_code" placeholder="Zip Code" control={control} />
                                {errors?.zip_code && <p style={{ color: 'red' }}>{errors?.zip_code.message}</p>}
                            </div>
                        </div>

                       <button type="submit" class="btn">Save</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AddNewAddress;
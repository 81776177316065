import React, { useState } from 'react';
import NavBar from '../Componants/NavBar';
import SpBanner from '../images/lamborgini.png';
import Footer from '../Componants/Footer';
import LeftArrow from '../images/left-arrow.svg'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import { message } from 'antd';
import { ResendOtp } from '../Redux/Features/Authslice';
import { useDispatch } from 'react-redux';
import { VerifyMobileOtpCode } from '../Redux/Features/Userslice';
function VerifyPhone() {
    const {state} = useLocation()
    const dispatch=useDispatch()
    const navigate =useNavigate()
    console.log(state,'verify phone');
const [OTP, setOTP] = useState(new Array(6).fill(""));
const handleChange = (element, index) => {
       
    if (isNaN(element.value)) return false;
    setOTP([...OTP.map((d, idx) => (idx === index) ? element.value : d)]);
    
    
}
const inputfocus = (elmnt) => {
    
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {    
        elmnt.target.form.elements[next].focus()
      }
    }
    else {
      console.log("next");         
        const next = elmnt.target.tabIndex;
        if (next < 6 && !isNaN(elmnt.key)) {
            
          elmnt.target.form.elements[next].focus()
        }
    }

  }
  let finalString = OTP.join("");
    //   function for OTP verification
    const handleVerifyOtp = () => {


 


        dispatch(VerifyMobileOtpCode({ sessionId: state.data, finalString })).then((res) => {
            console.log(res);

   if (res.type==="user/verifyMobileCode/fulfilled") {
       message.success(res.payload.message);
       if(state.case==="verification"){
        navigate('/success')
       }else{
        navigate('/success',{state:{clear:true}})
       }
       
   } else {
       message.info(res.payload.message)
   }

            


        })

    }
  
  return (
    <div>
        <NavBar />
        <div className="bannerSec spBanner" style={{ backgroundImage: `url('${SpBanner}')` }}>
            <div className="WrapperBnr">                
            </div>
        </div>
        <div className="editProfile pb60">
            <div className="container">
                <div className="row epRow">
                <div className="epIn col-lg-6">
                    <div className="epHd ep-change">
                        <h2 className='epHd'> 
                            <span>
                                <Link to="/profile-view" > <img src={LeftArrow} alt="" /> </Link>
                            </span> 
                            Verify Phone
                        </h2>
                        <span className='ep-change--sub'>We send a six digit verification code to {state?.phone}</span>
                    </div>
                    <div className="product--name epDetail">
                        <div className="epForm register-form">
                            <div className="form-controller">
                            <form className="formWrap formWrap--item changeItemWrp">
                                        {
                                            OTP.map((data, i) => {
                                                return (
                                                    <>
                                                        <input
                                                            className='otp-field'
                                                            type="text"
                                                            name="otp"
                                                            maxLength="1"
                                                            key={i}
                                                            value={data}
                                                            onChange={e => handleChange(e.target, i)}
                                                            onFocus={e => e.target.select()}
                                                            onKeyUp={e => inputfocus(e)}
                                                            tabIndex={i+1}
                                                        />
                                                    </>
                                                );
                                            })
                                        }
                                    </form>                                           
                            </div>
                            {/* <Link to="/success"> */}
                               <button onClick={handleVerifyOtp}  type="submit" class="btn btn-save">NEXT</button>
                            {/* </Link>    */}
                        </div>
                        <div className="footertemp">
                        <p class="fp">Don't receive code? <span className='ss' onClick={()=>dispatch(ResendOtp(state?.data)).then((resp)=> resp.type=== "auth/Resendotp/fulfilled"? message.success('verification code sent successfully' ) : message.warn('failed to sent verification code') )} >Resend</span></p>
                        </div>
                    </div>                
                </div>
                </div>
            </div>
        </div>
        <Footer noTopSpace/>
    </div>
  );
}

export default VerifyPhone;
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SpBanner from '../images/lamborgini.png';
import regBg from '../images/regImg.png';
import Footer from '../Componants/Footer'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { FeedNewPassword } from '../Redux/Features/Authslice';
import { message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/fontawesome-free-solid'
import { library } from '@fortawesome/fontawesome-svg-core'
library.add(faEye, faEyeSlash);
function NewPassword() {
    const [visible, setVisible] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const { state } = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors } } = useForm()
    console.log(state, 'state new password');

    const onSubmit = (data) => {
        console.log(data)
        if (data.password === data.confirm_password) {
            dispatch(FeedNewPassword({ data, sessionId: state?.data })).then((res) => {
            console.log(res, 'response after cghanging forgot password');
            if (res.payload.message === "Password changed") {
                message.info('Password changed successfully')
                navigate('/success-login')
            } else {
                message.warning(res?.payload?.response?.data?.message)
            }
        })
            
        }else{
            message.warning('Password mismatch')
        }
        

    }

    return (
        <div>
            <div className="bannerSec spBanner" style={{ backgroundImage: `url('${SpBanner}')` }}>
                <div className="WrapperBnr">

                </div>
            </div>
            <div className="accRegistration pb60">
                <div className="container">
                    <div className="row accRow">
                        <div className="col-lg-6 colRgLeft p-0">
                            <div className="rgLeftWrap">
                                <div className="rgLcon h100">
                                    <div className="rgLHd">
                                        <h2><span>Enjoy the </span> <span>Experience with us!</span></h2>
                                    </div>
                                    <div className="rgLimg">
                                        <img src={regBg} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 colRgRight p-0">
                            <div className="rgRtWrap formstructure">
                                <div className="formBody">
                                    <div className="rgRtHd">
                                        <h2>Create a new password</h2>
                                        <span>Your new password must be different from previous used password</span>
                                    </div>
                                    <div action="#" class="register-form">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="product--name epDetail">
                                                <div className="epForm register-form">
                                                    <div className="form-controller">
                                                        <label htmlFor="">New Password</label>
                                                        <input type={visible ? "text" : "password"} name="password" placeholder="Password" {...register('password', { required: 'Password is required', minLength: { value: 6, message: 'password must be minimum 6 characters' } })} />

                                                        {errors?.password && <p style={{ color: 'red' }}>{errors?.password.message}</p>}
                                                        <span className="passwordToggleIcon">
                                                            <FontAwesomeIcon icon={visible ? "eye" : "eye-slash"}
                                                                onClick={() => setVisible(!visible)} />
                                                        </span>
                                                    </div>
                                                    <div className="form-controller">
                                                        <label htmlFor="">Confirm Password</label>
                                                        <input type={visible2 ? "text" : "password"} name="confirm_password" placeholder="Password" {...register('confirm_password', { required: 'confirm Password is required', minLength: { value: 6, message: 'password must be minimum 6 characters' } })} />

                                                        {errors?.confirm_password && <p style={{ color: 'red' }}>{errors?.confirm_password.message}</p>}
                                                        <span className="passwordToggleIcon">
                                                            <FontAwesomeIcon icon={visible2 ? "eye" : "eye-slash"}
                                                                onClick={() => setVisible2(!visible2)} />
                                                        </span>
                                                    </div>
                                                    <button type="submit" class="btn btn-save updatebtn">UPDATE</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="register-form">
                                    <p class="message">Don't have an account? <Link to='/user-info'><a href="#">Register</a></Link> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer noTopSpace />
        </div>

    );

}

export default NewPassword;

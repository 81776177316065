import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../Axios'
import Cookies from 'js-cookie'

export const GetCars = createAsyncThunk(
    'data/getcars',
    async (obj, { rejectWithValue }) => {

        try {
            const res = await axios.get(`/vehicle?offset=0&limit=10&populate=%5B%22vehicle_image%22%5D`)
            return res.data.data.vehicles
        } catch (error) {
            return rejectWithValue(error)

        }

    }
)
export const GetCarById = createAsyncThunk(
    'data/getcarsbyId',
    async (obj, { rejectWithValue }) => {

        try {
            const res = await axios.get(`/vehicle/${obj}?populate=%20%5B%22vehicle_image%22%2C%22review%22%2C%20%22review.user%22%5D`,
                { headers: { Authorization: `Bearer ${Cookies.get('token')}` } })
            return res.data.data.vehicle
        } catch (error) {
            return rejectWithValue(error)

        }

    }
)
export const GetCheckSlots = createAsyncThunk(
    'data/checkslots',
    async ({ passDate, timezone, distance, id }, { rejectWithValue }) => {


        try {
            const res = await axios.post(`/vehicle_availability/free_slots`, {
                ride_date: passDate,
                vehicle_id: parseInt(id),
                timezone: timezone,
                freeslot: parseInt(distance)
            }, { headers: { Authorization: `Bearer ${Cookies.get('token')}` } })
            return res.data.data.slots
        } catch (error) {
            return rejectWithValue(error)

        }

    }
)
export const GetPickupLocations = createAsyncThunk(
    'data/getpickuplocation',
    async (obj, { rejectWithValue }) => {


        try {
            const res = await axios.get(`/pickup_location?offset=0&limit=10`, { headers: { Authorization: `Bearer ${Cookies.get('token')}` } })
            return res.data.data.pickup_locations
        } catch (error) {
            return rejectWithValue(error)

        }

    }
)
export const BookRide = createAsyncThunk(
    'data/bookRide',
    async ({ datetime, time, distance, id, locationId }, { rejectWithValue }) => {


        try {
            const res = await axios.post(`/vehicle_availability/check_slot`, {

                "ride_date": datetime,
                "start_at": time,
                "duration": parseInt(distance),
                "vehicle_id": parseInt(id),
                "location_id": locationId

            },
                { headers: { Authorization: `Bearer ${Cookies.get('token')}` } })
            return res.data.data
        } catch (error) {
            return rejectWithValue(error.response.data.message)

        }

    }
)
export const ApplyCoupencode = createAsyncThunk(
    'data/Applycoupen',
    async (obj, { rejectWithValue }) => {


        try {
            const res = await axios.post(`/coupon_code/apply`, {


                code: obj.code,
                total_cost: obj.price,
                category: "ride"


            },
                { headers: { Authorization: `Bearer ${Cookies.get('token')}` } })

            if (res.status !== 200) {

            } else {
                return res.data.data
            }

        } catch (error) {
            return rejectWithValue(error.response.data.message)

        }

    }
)
export const RemoveCoupencode = createAsyncThunk(
    'data/Removecoupen',
    async (obj, { rejectWithValue }) => {

        const { discount, price } = obj
        console.log('remove obj', discount, price);

        try {
            const res = await axios.post(`/coupon_code/remove`, {


                discount: discount,
                total_cost: price


            },
                { headers: { Authorization: `Bearer ${Cookies.get('token')}` } })

            if (res.status !== 200) {
                console.log('preshnam is', res);
            } else {
                return res.data.data
            }

        } catch (error) {
            return rejectWithValue(error.response.data.message)

        }

    }
)
export const GetMyRides = createAsyncThunk(
    'data/myRides',
    async (obj, { rejectWithValue }) => {


        try {
            const res = await axios.get(`/ride/customer?offset=0&sort=%5B%5B%22id%22%2C%22desc%22%5D%5D`,
                { headers: { Authorization: `Bearer ${Cookies.get('token')}` } })
            return res.data.data.rides
        } catch (error) {
            return rejectWithValue(error.response.data.message)

        }

    }
)
export const BookMyRides = createAsyncThunk(
    'data/BookRides',
    async (obj, { rejectWithValue }) => {
        const { Ridecost, serviceCharge, offer, total, duration, vehicle_rate, location_id, vehicle_id, coupen_id, start_at, ride_date } = obj
        console.log(location_id, 'obj is');
        try {
            const res = await axios.post(`/user_payment/ride`,

                {
                    coupon_id: parseInt(coupen_id),

                    service_charge: parseInt(serviceCharge),
                    discount: parseInt(offer),
                    cost_per_hour: parseInt(vehicle_rate),
                    ride_cost: parseInt(Ridecost),
                    total_amount: parseInt(total),
                    vehicle_id: parseInt(vehicle_id),
                    location_id: location_id,
                    ride_date: ride_date,
                    start_at: start_at,
                    duration: parseInt(duration)
                },

                { headers: { Authorization: `Bearer ${Cookies.get('token')}` } })
            return res.data.data
        } catch (error) {
            return rejectWithValue(error.response.data.message)

        }

    }
)
export const ContactUs = createAsyncThunk(
    'data/contactUs',
    async (obj, { rejectWithValue }) => {
        const { name, email, phone, phone_code, message, time, wayTocontact } = obj
        console.log(phone_code, 'obj is');
        try {
            const res = await axios.post(`/contact_us`,

                {
                    name: name,
                    email: email,
                    phone_code: phone_code,
                    phone: phone,
                    contact_time: time,
                    contact_via: wayTocontact,
                    subject: "Cancel ride",
                    message: message,
                    category: "ride"
                },

                { headers: { Authorization: `Bearer ${Cookies.get('token')}` } })
            return res.data
        } catch (error) {
            return rejectWithValue(error.response.data.message)

        }

    }
)
export const RideDetailsById = createAsyncThunk(
    'data/RideDetialsByid',
    async (obj, { rejectWithValue }) => {
        // const { name, email, phone, phone_code, message, time, wayTocontact } = obj

        try {
            const res = await axios.get(`ride/customer/${obj}`,
                { headers: { Authorization: `Bearer ${Cookies.get('token')}` } })
            return res.data.data.ride_detail
        } catch (error) {
            return rejectWithValue(error.response.data.message)

        }

    }
)
export const CancelRide = createAsyncThunk(
    'data/CancelRide',
    async (obj, { rejectWithValue }) => {
        console.log(obj, 'Ride id in data slice' );

        try {
            const res = await axios.post(`/ride/reschedule_request`,
            {
                "ride_id": obj
            },
                { headers: { Authorization: `Bearer ${Cookies.get('token')}` } })
            return res.data.data.ride_detail
        } catch (error) {
            return rejectWithValue(error.response.data.message)

        }

    }
)


const initialState = {

    data: [],
    singleCar: {},
    loading: false,
    success: false,
    error: '',
    itemCount: null,
    isauth: false,
    rejected: false,
    sessionId: '',
    coupenerror: '',
    timeSlots: [],
    pickuplocations: [],
    bookingDetails: {},
    coupenOffer: {},
    myRides: [],
    bookingError: '',
    bookError: '',
    CoupenRemove: {},
    RideDetailsUser:{}


}

const Dataslice = createSlice({
    name: 'data',
    initialState,
    reducers: {},
    extraReducers: {

        [GetCars.pending]: (state) => {
            state.loading = true
        },
        [GetCars.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.data = payload
            state.success = true
        },
        [GetCars.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = 'rejected'

        },
        [GetCarById.pending]: (state) => {
            state.loading = true
        },
        [GetCarById.fulfilled]: (state, { payload }) => {
            console.log(payload);
            state.loading = false
            state.singleCar = payload
            state.success = true
        },
        [GetCarById.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = 'rejected'

        },
        [GetCheckSlots.pending]: (state) => {
            state.loading = true
        },
        [GetCheckSlots.fulfilled]: (state, { payload }) => {
            console.log(payload);
            state.loading = false
            state.timeSlots = payload
            state.success = true
        },
        [GetCheckSlots.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = 'rejected'

        },
        [GetPickupLocations.pending]: (state) => {
            state.loading = true
        },
        [GetPickupLocations.fulfilled]: (state, { payload }) => {
            console.log(payload);
            state.loading = false
            state.pickuplocations = payload
            state.success = true
        },
        [GetPickupLocations.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = 'rejected'

        },
        [BookRide.pending]: (state) => {
            state.loading = true
        },
        [BookRide.fulfilled]: (state, { payload }) => {
            console.log(payload);
            state.loading = false
            state.bookingDetails = payload
            state.success = true
        },
        [BookRide.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload

        },
        [ApplyCoupencode.pending]: (state) => {
            state.loading = true
            state.coupenerror = ''
        },
        [ApplyCoupencode.fulfilled]: (state, { payload }) => {
            console.log(payload);
            state.loading = false
            state.coupenOffer = payload
            state.coupenerror = ''


            state.success = true
        },
        [ApplyCoupencode.rejected]: (state, { payload }) => {
            state.loading = false
            console.log('rejected payload is', payload);
            state.coupenerror = payload


        },
        [RemoveCoupencode.pending]: (state) => {
            state.loading = true

        },
        [RemoveCoupencode.fulfilled]: (state, { payload }) => {
            console.log(payload);
            state.loading = false
            state.CoupenRemove = payload
            state.coupenOffer={}



            state.success = true
        },
        [RemoveCoupencode.rejected]: (state, { payload }) => {
            state.loading = false

            state.coupenerror = payload


        },
        [GetMyRides.pending]: (state) => {
            state.loading = true
        },
        [GetMyRides.fulfilled]: (state, { payload }) => {
            state.myRides = payload
            state.success = true
            state.loading = false
        },
        [GetMyRides.rejected]: (state, { payload }) => {
            state.loading = false
            state.bookingError = payload
            state.loading = false
        },
        [BookMyRides.pending]: (state) => {
            state.loading = true
        },
        [BookMyRides.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.myRides = payload
            state.success = true
        },
        [BookMyRides.rejected]: (state, { payload }) => {
            state.loading = false
            state.bookError = payload
        },
        [RideDetailsById.pending]: (state) => {
            state.loading = true
            state.RideDetailsUser={}
        },
        [RideDetailsById.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.RideDetailsUser = payload
            // state.success = true
        },
        [RideDetailsById.rejected]: (state, { payload }) => {
            state.loading = false
            state.bookError = payload
        },

    }

});

// export const {} = Dataslice.actions

export default Dataslice.reducer






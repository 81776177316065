import React, { useEffect } from 'react'
import NavBar from '../Componants/NavBar';
import SpBanner from '../images/lamborgini.png';
import Footer from '../Componants/Footer';
import Success from '../images/success.png'
import { useLocation, useNavigate} from 'react-router-dom'
import Cookies from 'js-cookie'
function SuccessPage() {
const {state}=useLocation()
const navigate=useNavigate()
    useEffect(() => {
      console.log(state?.clear);
      state.clear && setTimeout(() => {
        Cookies.remove("token");
        localStorage.removeItem('revup user')
      }, 5000);
    }, [state])
    
  return (
    <div>
         <NavBar />
        <div className="bannerSec spBanner" style={{ backgroundImage: `url('${SpBanner}')` }}>
            <div className="WrapperBnr">                
            </div>
        </div>
        <div className="editProfile pb60">
            <div className="container">
                <div className="row epRow">
                <div onClick={()=>navigate('/login')} className="epIn col-lg-4 epSuccess">
                    <div className="product--name epDetail successView">
                        <div className="success_hd">
                            <img src={Success} alt="" />
                        </div>
                        <div className="success-dec">
                            <h2>Success</h2>
                            {state?.case ? <p>You have successfully verified your Mobile number.</p> : <p>You have successfully change your email. Please login with the new email to continue.</p> }
                           
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <Footer noTopSpace/>
    </div>
  );
}

export default SuccessPage;
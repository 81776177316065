import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from '../../Axios'
import Cookies from 'js-cookie'

 



export const Register = createAsyncThunk(
    'auth/register',
    async (obj, { rejectWithValue }) => {
              console.log('user Items',obj);
              sessionStorage.setItem('first_name',obj.first_name)
              sessionStorage.setItem('last_name',obj.last_name)
              sessionStorage.setItem('email',obj.email)
              sessionStorage.setItem('phone_code',obj.phone_code)
              sessionStorage.setItem('phone',obj.phone)
              sessionStorage.setItem('password',obj.password)
        try {
            const res = await axios.post(`/auth/email/validate`,{
                email:obj.email,
                phone_code :obj.phone_code,
                phone:obj.phone
            })
            return res.data
        } catch (error) {
            return rejectWithValue(error)

        }

    }
)
export const Signup = createAsyncThunk(
    'auth/signup',
    async (obj, { rejectWithValue }) => {
              console.log('user Items',obj);
              
              
              
              
              
        try {
            const res = await axios.post(`/auth/signup`,


                {
                    first_name: sessionStorage.getItem('first_name'),
                    last_name: sessionStorage.getItem('last_name'),
                    email:sessionStorage.getItem('email'),
                    phone_code :sessionStorage.getItem('phone_code'),
                    phone:sessionStorage.getItem('phone'),
                    password: sessionStorage.getItem('password'),
                    session_id: obj,
                    info: {}
                  } 
                
                
                
            )
            return res.data
        } catch (error) {
            return rejectWithValue(error)

        }

    }
)
export const Login = createAsyncThunk(
    'auth/login',
    async ({email,password}, { rejectWithValue }) => {
         
        try {
            const res = await axios.post(`/auth/local`, {
                username: email,
                password: password,
                info: {
                role_id: 3
                }
              })
              if (res.status === 200) {
                 
                return res.data
                  
              } else {
                return rejectWithValue(res)
              }
                
              
            
        } catch (error) {
            return rejectWithValue(error)

        }

    }
)
export const VerifyOTP = createAsyncThunk(
    'auth/verifyotp',
    async ({sessionId,finalString}, { rejectWithValue }) => {
        console.log('finalString',finalString);
        try {
            const res = await axios.post(`/auth/otp/verify`,{
                session_id:sessionId,
                otp:finalString
               
            })
            
            if (res.status ===400){
                console.log('error');

            }else{
                console.log('error res',res);
            }
            return res.data
        } catch (error) {
            return rejectWithValue(error)

        }

    }
)
export const ResendOtp = createAsyncThunk(
    'auth/Resendotp',
    async (obj, { rejectWithValue }) => {
        console.log('obs',obj);
        try {
            const res = await axios.post(`/auth/otp/send`,{
                session_id:obj,
                
               
            })
            
            // if (res.status ===400){
            //     console.log('error');

            // }else{
            //     console.log('error res',res);
            // }
            return res.data
        } catch (error) {
            return rejectWithValue(error)

        }

    }
)
export const Logout = createAsyncThunk(
    'auth/logout',
    async (obj, { rejectWithValue }) => {
        
        try {
            const res = await axios.post(`/auth/logout`,{
                
                    "session_id": "62272c4810bdd11886f59746"
                  
               
            },
            {headers:{Authorization:`Bearer ${Cookies.get('token')}`}}
            )
            
            if (res.status === 200) {
                return res.data
            } else {
                return res
            }
            
            
        } catch (error) {
            return rejectWithValue(error)

        }

    }
)
export const GetLoggedinUser = createAsyncThunk(
    'auth/getUser',
    async (obj, { rejectWithValue }) => {
        
        try {
            const res = await axios.get(`/user/me`,
            {headers:{Authorization:`Bearer ${Cookies.get('token')}`}}
            )
            
            if (res.status === 200) {
                return res.data 
            } else {
                return res
            }
            
            
        } catch (error) {
            return rejectWithValue(error)

        }

    }
)
export const UpdatePassword = createAsyncThunk(
    'auth/Updatepassword',
    async (obj, { rejectWithValue }) => {
        console.log(obj);
        try {
            const res = await axios.put(`/user/password`,{
               password:obj.newpwd,
               old_password:obj.currentpwd,
            },
            {headers:{Authorization:`Bearer ${Cookies.get('token')}`}}
            )
            
            if (res.status === 200) {
                return res.data 
            } else {
                return res
            }
            
            
        } catch (error) {
            return rejectWithValue(error)

        }

    }
)
export const ForgotPassword = createAsyncThunk(
    'auth/Forgotpassword',
    async (obj, { rejectWithValue }) => {
        console.log(obj);
        try {
            const res = await axios.post(`/auth/password/forgot`,{
               email:obj.email,
               
            },
            {headers:{Authorization:`Bearer ${Cookies.get('token')}`}}
            )
            
            return res.data
            
            
        } catch (error) {
            return rejectWithValue(error)

        }

    }
)
export const FeedNewPassword = createAsyncThunk(
    'auth/FeedNewpassword',
    async (obj, { rejectWithValue }) => {
        console.log(obj,'feed password');
        try {
            const res = await axios.post(`/auth/password/reset`,{
                session_id: obj.sessionId,
                password: obj.data.password
               
            },
            {headers:{Authorization:`Bearer ${Cookies.get('token')}`}}
            )
            
            if (res.status === 200) {
                return res.data 
            } else {
                return res
            }
            
            
        } catch (error) {
            return rejectWithValue(error)

        }

    }
)

const initialState = {
user:{},
isauth:false,
rejected:false,
error:'',
sessionId:'',
loading:false
}

const Authslice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers:{
    [Register.pending]: (state) => {
        
        
    },
    [Register.fulfilled]: (state,{payload}) => {
        state.isauth = true
        
        state.sessionId =payload.data.session_id
    },
    [Register.rejected]: (state) => {
        state.rejected = true
    },
    [Login.pending]: (state) => {
        state.loading =true
        
    },
    [Login.fulfilled]: (state,{payload}) => {
        console.log('payload',payload.data);
        state.loading =false
        state.isauth = true
        // Cookies.set('token',payload.data.token)
        // localStorage.setItem('revup user',payload.data.user.full_name)
        
    },
    [Login.rejected]: (state,{payload}) => {
        state.loading =false
        state.rejected = true
        state.error=payload.message
    },
    [VerifyOTP.pending]: (state) => {
        
    },
    [VerifyOTP.fulfilled]: (state,{payload}) => {
        state.isauth = true
        
    },
    [VerifyOTP.rejected]: (state,{payload}) => {
        state.rejected = true
        state.error=payload.message
    },
    [Logout.pending]: (state) => {
        
    },
    [Logout.fulfilled]: (state,{payload}) => {
        Cookies.remove("token");
        localStorage.removeItem('revup user')
        
    },
    [Logout.rejected]: (state,{payload}) => {
        state.rejected = true
        state.error=payload.message
    },
    [GetLoggedinUser.pending]: (state) => {
        
    },
    [GetLoggedinUser.fulfilled]: (state,{payload}) => {
        state.user =payload.data
        
    },
    [GetLoggedinUser.rejected]: (state,{payload}) => {
        state.rejected = true
        state.error=payload.message
    },
  }
});

// export const {} = Authslice.actions

export default Authslice.reducer